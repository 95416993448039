@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
body{font-family: 'Roboto', sans-serif; margin: 0; padding: 0;}
img{  max-width: 100%;}
a:hover{ text-decoration: none !important;}
p:hover{ text-decoration: none !important; }
h1, h2, h3, h4, h5, h6, ol, li, ul, a, img, p, span, strong{ padding: 0px; margin: 0px;}
a:hover,a:focus{ outline: none; text-decoration: none}

*{ padding: 0; margin: 0; box-sizing: border-box}

a {text-decoration: none;}

.react-datepicker-wrapper {
    width: 100%;
    display: inline-block;
    padding: 0;
    border: 0;
}
.jobseekerMailImageBox {
	max-width: 300px;
}
.jobseekersListingNoData {
	margin-top: 100px;
}
.uploadCvButton {
	color: #294A9C;
    font-weight: 500;
	padding: 9px 6px;
	min-width: 175px;
    margin-left: 10px;
    border-radius: 3px;
	width: max-content;
    background-color: white;
    border: 2px #294A9C solid;
}
.uploadCvButton:not(:disabled):hover {
	color: #FF0B0F;
	background-color: white;
    border: 2px #FF0B0F solid;
}
@media screen and (max-width: 767px) {
	.uploadCvButton {
		font-size: 14px;
	}
}
.homeJobs {
    background: transparent !important;
    border-radius: 0;
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 25px;
    margin-bottom: 13px;
    border: none;
}
.fourthBlock .homeJobs {
    background: transparent !important;
    border-radius: 0;
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 25px;
    margin-bottom: 13px;
}
.errorPage {
	text-align: center;
}
.errorImage{
	margin-top: 30px;
	max-width: 600px;
}
.empPaginationButton {
	margin: 20px 0 20px 0px;
}
.empPaginationData {
	text-align: center;
}
.skillPackage {
	z-index: 99;
}
.MembershipMainBx .owl-nav {
    display: none;
}

/* loader */
.loader-container{
	position: fixed;
	width: 100%;
	height: 100%;
	background: #ffffff00 url('/public/Images/loaderNew.gif') no-repeat center;
	z-index: 999;
}
@keyframes rotation {
	0% {
	  	transform: rotate(0deg);
	}
	100% {
	  	transform: rotate(360deg);
	}
}
@keyframes animloader {
	50% {
		transform: scale(1) translate(-50%, -50%);
  	}
}

.validation .error{
    font-size: 17px;
    height: 50px;
    font-weight: 400;
    min-width: fit-content;
    /* max-width: 1100px; */
    border: 1px dashed #ff0000;
    /* padding: 20px 20px; */
}
.validation .input-error {
    font-size: 17px;
    height: 70px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
    padding: 20px 20px;
}
.employerLogin .error {
    font-size: 17px;
    height: 50px;
    font-weight: 400;
    min-width: fit-content;
    /* max-width: 1100px; */
    border: 1px dashed #ff0000;
    /* padding: 20px 20px; */
}
.employerLogin .input-error {
    font-size: 17px;
    height: 50px;
    font-weight: 400;
    min-width: fit-content;
    /* max-width: 1100px; */
    border: 1px dashed #ff0000;
    /* padding: 20px 20px; */
}

.createJob .error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}
.createJob .input-error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}

.createJob .input-error-react-select {
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
	border-radius: 6px;
    border: 1px dashed #ff0000;
}
.editProfile .error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}
.editProfile .input-error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}
.editProfile .input-error-react-select {
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
	border-radius: 6px;
    border: 1px dashed #ff0000;
}
.removeButtonJobseeker {
	gap: 12px;
	display: flex;
	justify-content: space-between;
}
.form-select {
	font-size: 16px;
	color: #797979;
	min-width: fit-content;
	box-shadow: none !important;
	min-width: -moz-fit-content;
	border: 1px dashed #a7a6a6;
	padding: 20px 34px 20px 20px;
}
.formItems .form-select {
	padding: 6px 34px 6px 12px;
}
.formButton {
	padding: 8px 0 8px 0;
}
.button1 {
	color: white;
	font-size: 17px;
	font-weight: 500;
	padding: 10px 25px;
	border-radius: 3px;
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}
.button1:disabled {
	opacity: 0.7;
	color: #FFFFFF;
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}
.button1:not(:disabled):hover {
	color: #fff;
	border-color:#294A9C;
	background-color: #294A9C;
}
.dropdown .button1 {
	color: white;
	font-size: 17px;
	font-weight: 500;
	padding: 10px 25px;
	border-radius: 3px;
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}

.button2 {
	font-size: 17px;
  	color: #294a9c;
  	font-weight: 500;
	padding: 10px 25px;
	border-radius: 3px;
  	background-color: white;
	border: 2px #294a9c solid;
}
.button2:disabled {
	opacity: 0.7;
	color: #294A9C;
	background-color: white;
	border: 2px #294A9C solid;
}
.button2:not(:disabled):hover {
	color: #FF0B0F;
	border-color:#FF0B0F;
	background-color: #fff;
}
.navButton1 {
	color: white;
	font-size: 16px;
	font-weight: 500;
	min-width: 135px;
	margin-left: 15px;
	padding: 9px 25px;
	border-radius: 3px;
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}
.navButton1:disabled {
	opacity: 0.7;
	color: #fff;
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}

.navButton1:not(:disabled):hover {
	color: #fff;
	border-color:#294A9C;
	background-color: #294A9C;
}
.navButton2 {
	color: #f3734c;
	background-color: white;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 16px;
	padding: 9px 25px;
	border-radius: 3px;
	margin-left: 10px;
}

.navButton2:hover {
	color: #294a9c;
	background-color: #fff;
	border-color:#294a9c;
}
.seoText,
.seoText ul,
.seoText ol {
	margin-bottom: 16px;
}
.seoText h2 {
	font-size: 24px;
	margin-top: 16px;
	margin-bottom: 16px;
}
.seoText h3 {
	font-size: 16px;
	margin-top: 16px;
	margin-bottom: 16px;
}
.seoText button {
	margin: 0;
	padding: 0;
	border: none;
	background: none;
	color: gray;
}
.seoText button:hover {
	text-decoration: underline;
}

/* Navbar */

.navbar-brand {
    margin-right: 0 !important;
}
.navbardefault {
	height: 100%;
}
.frontendNavLogo {
	margin-left: 50px;
	margin-right: 50px;
}
.defaultnavbar {
	top: 0;
	left: 0;
	height: 80px;
	z-index: 999;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
@media screen and (min-width: 768px) {
	.defaultnavbar {
		position: sticky;
	}
}
.defaultnavbar {
	list-style: none;
}
.navigation {
	width: 100%;
	height: 100%;
	display: flex;
  	align-items: center;
  	flex-direction: row;
  	justify-content: space-around;
}
.navbardefault .navitems {
	height: 100%;
	font-size: 18px;
	color: #3c3c3c;
	font-weight: 300;
	position: relative;
	font-family: 'Roboto', sans-serif;
}
.navUsername {
	text-overflow: ellipsis;
}
.navitems:hover {
	color: #f81f1f;
}
.navbardefault .navitems.active {
	color: #f81f1f;
	font-weight: 500;
}
.navbardefault .navitems:hover:before,
.navbardefault .navitems.active::before{
	left: 0;
	content:"";
	width: 100%;
	height: 3px;
	bottom: -27px;
	cursor: default;
	position: absolute;
	background: #294a9c;
}
.navbardefault .SearchIcon {
	position: relative;
}
.navbardefault .SearchIcon:hover:before,
.navbardefault .SearchIcon.active::before {
	left: 0;
	content:"";
	width: 100%;
	height: 3px;
	bottom: -24px;
	cursor: default;
	position: absolute;
	background: #294a9c;
}
.navbardefault .languageSweatcher {
	position: relative;
}
.languageSweatcher li {
	list-style: none;
}
.navbardefault .navButton1 {
	margin: 0;
	padding: 11px 25px;
	border-color: #FF0B0F;
	background-color: #FF0B0F;
}
.navbardefault .navButton1.btn {
	color: white;
}
.navbardefault .navButton1:hover {
	border-color:#294A9C;
	background-color: #294A9C;
}
.navbardefault .navButton2 {
	margin: 0;
	color: #294A9C;
	padding: 11px 25px;
	border: 2px #294A9C solid;
}
.navbardefault .navButton2:hover {
	color: #FF0B0F;
	border-color:#FF0B0F;
	background-color: white;
}
.barometerNavigation {
	gap: 40px;
	display: flex;
	align-items: center;
}
.loginButtons {
	gap: 40px;
	display: flex;
}
@media screen and (max-width: 991px) {
	.loginButtons {
		display: block;
	}
	.barometerNavigation {
		display: block;
	}
}
@media screen and (min-width: 992px) {
	.navbardefault .languageSweatcher:hover:before,
	.navbardefault .languageSweatcher.active::before {
		left: 0;
		content:"";
		width: 100%;
		height: 3px;
		bottom: -24px;
		cursor: default;
		position: absolute;
		background: #294a9c;
	}
}
@media (max-width: 1399px) {
	.navbardefault .navitems {
		font-size: 14px;
	}
}

/* Userpage */

.marqueeText {
	background-color: #294a9c;
	color: white;
	padding: 15px 0 15px 0;
}
.marqueeLink {
	color: white;
	font-size: large;
}
.sliderbody {
	color: #25242A;
	overflow: hidden;
	text-align: left;
	position: relative;
	background: url('/public/Images/mainBanner/main-banner-375.webp');
	background-size: cover;
}
.lowerSlider {
	height: 634px;
	padding-top: 140px;
}
.slidertext h1 {
	font-size: 64px;
	color: #FFFFFF;
	font-weight: 500;
	max-width: 834px;
	line-height: 125%;
	margin-bottom: 20px;
}
.slidertext h1 span {
	font-weight: 800;
}
.slidertext p {
	font-size: 18px;
	color: #FFFFFF;
}
.searcharea {
	padding: 8px;
	display: flex;
	margin-top: 32px;
	max-width: 654px;
	border-radius: 5px;
	flex-direction: row;
	background-color: white;
	border: 1px solid #bcbcbc;
	justify-content: space-between;
	box-shadow: 0 12px 20px 0 rgba(0,0,0,0.12);
}
.inputGrp {
	width: 100%;
	display: flex;
	position: relative;
	align-items: center;
}
.searcharea .form-control {
	border: none;
	opacity: 0.8;
	font-size: 18px;
	font-weight: 300;
	border-radius: 3px;
	box-shadow: none !important;
}
.searcharea .button1 {
	font-size: 16px;
	padding: 7px 25px;
}
.slidertext2 {
	margin-top: 50px;
  }
.jobs-clients-posted {
	padding: 0 40px;
	display: inline-block;
	border-left: 1px solid #FFFFFF;
}
.jobs-clients-posted:first-child {
	padding-left: 0;
	border-left: none;
}
.jobs-clients-posted h3 {
	font-size: 42px;
	color: #FFFFFF;
	font-weight: 500;
	margin-bottom: 10px;
}
.jobs-clients-posted h6 {
	font-size: 20px;
	color: #FFFFFF;
	font-weight: 300;
}
@media screen and (min-width: 1800px) {
	.sliderbody {
		background: url('/public/Images/mainBanner/main-banner-1800.webp');
		background-size: cover;
	}
}
@media screen and (min-width: 1400px) and (max-width: 1799px) {
	.sliderbody {
		background: url('/public/Images/mainBanner/main-banner-1400.webp');
		background-size: cover;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
	.sliderbody {
		background: url('/public/Images/mainBanner/main-banner-1200.webp');
		background-size: cover;
	}
	.slidertext h1 {
		font-size: 50px;
		max-width: 680px;
	}
	.searcharea {
		max-width: 620px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.sliderbody {
		background: url('/public/Images/mainBanner/main-banner-992.webp');
		background-size: cover;
	}
	.slidertext h1 {
		font-size: 50px;
		max-width: 680px;
	}
	.searcharea {
		max-width: 620px;
	}
	.jobs-clients-posted h3 {
		font-size: 38px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.sliderbody {
		background: url('/public/Images/mainBanner/main-banner-768.webp');
		background-size: cover;
	}
	.lowerSlider {
		padding-top: 220px;
	}
	.slidertext h1 {
		font-size: 48px;
		max-width: 620px;
	}
	.searcharea {
		max-width: 594px;
	}
	.jobs-clients-posted h3 {
		font-size: 36px;
		margin-bottom: 4px;
	}
}
@media screen and (max-width: 767px) {
	.lowerSlider {
		padding-top: 200px;
	}
	.slidertext h1 {
		font-size: 32px;
		max-width: 340px;
	}
	.slidertext p {
		font-size: 16px;
	}
	.searcharea {
		padding: 0;
		width: 100%;
		border: none;
		background: none;
		box-shadow: none;
		margin-top: 28px;
		text-align: center;
		display: inline-block;
	}
	.inputGrp {
		border-radius: 5px;
		margin-bottom: 10px;
		padding: 2px 0 2px 12px;
		background-color: white;
		box-shadow: 0 12px 20px 0 rgba(0,0,0,0.12);
	}
	.searcharea .form-control {
		font-size: 16px;
		border-radius: 3px;
		text-overflow: ellipsis;
	}
	.searcharea .button1 {
		width: 100%;
	}
	.slidertext2 {
		margin-top: 24px;
	  }
	.jobs-clients-posted {
		padding: 0 20px;
	}
	.jobs-clients-posted h3 {
		font-size: 26px;
		margin-bottom: 4px;
	}
	.jobs-clients-posted h6 {
		font-size: 14px;
	}
}
.sliderbody .owl-dots {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.bottomButtons {
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
}
.adminBottomButtons {
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	text-align: center;
}
.CategorySection {
	padding: 70px 0;
}
.CategoryBx img {
  margin-bottom: 0;

}
.CategoryBx h5 {
	color: #25242a;
	font-size: 26px;
	font-weight: normal;
  	margin-top: 25px;
}
.CategoryBx p {
	color: #525252;
	font-size: 18px;
	margin-top: 10px;
	opacity: 0.8;
	max-width: 270px;
}
.subsecondblock {
  margin-top: 120px;
  text-align: center;
  position: relative;
}
.RightDotImg {
	position: absolute;
	right: -60px;
	top: 40px;
}
.LeftDotImg {
	position: absolute;
	left: -84px;
	top: 40%;
}
.subsecondblockHeading {
	font-weight: 500;
	color: #25242a;
	font-size: 40px;
}
.textGradient {
	position: relative;
}
.textGradient::before {
	position: absolute;
	left: 0;
	bottom: -6px;
	content: "";
	background: #CEE3ED;
	width: 100%;
	height: 28px;
	z-index: 0;
}
.SubHaddingTxt {
	position: relative;
}
.textGradient {
	position: relative;
	margin-left: 5px;
}
.ExploreCategory {
	margin-top: 60px;
}

.thirdBlock {
	padding: 70px 0;
  	background: url("/public/Images/mainBanner/mainSecondSection.jpg") top center;
  	background-size: cover;
  	background-repeat: no-repeat;
}

.BrowseJobsHadding {
	font-size: 40px;
	font-weight: 500;
	color: #fff;
}
.thirdBlockData {
	color: white;
	min-height: 552px;
	text-align: center;
}

.TB1item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5%;
}

.TB1subitems:hover {
  border-bottom: 3px solid #f3734c;
}

.TB2subitems {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fourthBlock h3 {
  text-align: center;
  margin-bottom: 30px;
}
.FeaturedJobsSection {
	margin-top: 100px;
}
.fourthBlock button {
	margin-top: 40px;
}
/* fifthBlock */

.fifthBlock {
  margin: 0 8% 5% 8%;
}

.fifthBlock .section2 {
  margin: 3% 0 3% 0;
}
.TopEmployersBxCardBx {
	max-width: 324px;
}
@media screen and (max-width: 767px) {
	.TopEmployersBxCardBx {
		margin-left: auto;
		margin-right: auto;
	}
}
.employersLogo {
  width: 65%;
  border-radius: 5px;
}
.EmployersLogoBx {
	width: 100%;
	padding: 20px;
	height: 233px;
}
.EmployersLogoLink {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (min-width: 768px) {
	.BorderRight {
		border-right: 1px #ddd solid;
	}
}
.BorderBottom {
	border-bottom: 1px #ddd solid;
}
.TopEmployersSection {
	padding: 70px 0 100px;
}
.TopEmployersBx {
	margin-top: 60px;
}

/* sixthBlock */

.sixthBlock {
  margin: 0 0 5% 0;
}
.sixthBlock {
    margin: 0 0 5%;
    overflow: hidden;
}
.DownloadAppSection {
	color: white;
}
.DownloadAppSection .flexImage {
	width: 100%;
}
.section1text {
	padding-top: 150px;
}
.section1text h2 {
	font-size: 60px;
	font-weight: 600;
}
.section1text h3 {
	font-size: 49px;
	font-weight: 500;
}

.section1button img{
  width: 35%;
  margin: 5% 3% 0 0;
}
.DownloadRightImg {
	position: relative;
	display: inline-block;
	width: 100%;
}
.phoneImage {
	position: absolute;
	z-index: 2;
	margin: 0;
	left: -80px;
	top: 70px;
}
.girlImage {
  position: relative;
  z-index: 1;
  float:inline-end;
  margin-top: -60px;
  margin-left: 100px;
}
.sixthBlock .section2 {

  padding: 5% 3% 0 6%;
}

.flexImage {
  width: 90%;
}
/* CategoryCard */

.catCard {
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
	width: 100%;
	min-height: 350px;
	position: relative;
	text-align: start;
	border: none;
	border-radius: 10px;
	padding: 40px 40px 20px; margin-bottom: 25px;
}
.ExploreCategory .catCardBody {
	padding: 0;
}
.catCard .catCardTitle {
	color: #201f1f;
	font-size: 25px;
	font-weight: normal;
}
.catCard .catCardSubtitle {
	color: #201f1f;
	font-size: 28px;
	font-weight: 500;
}
.subsecondblock .button1 {
	border: 1px #294a9c solid;
	font-size: 17px;
	padding: 12px 25px;
	margin-top: 40px;
}
.subsecondblock .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color:#f3734c;
}
.ImageCard {
	position: relative;
	margin: 40px 0 20px;
}
.imageChild1 {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
}

.imageChild2 {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 14px;
}


.catCardFooter {
	font-size: 17px;
	color: #505050;
	font-weight: normal;
	margin-top: 31px;
	line-height: 23px;
}


/* BrowseJobToggler */

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 3%;
  margin-bottom: 3%;

}
.BrowseJobstab {
	border-bottom: 1px solid rgba(255,255,255,0.5);
}
.BrowseJobstab .btn {
	color: white;
	font-size: 20px;
	position: relative;
}
.BrowseJobstab .btn.active::before {
	left: 0;
	content: "";
	height: 3px;
	width: 100%;
	bottom: -2px;
	position: absolute;
	background: #FF0B0F;
}
.BrowseJobstab .btn:first-child:active,
.BrowseJobstab .btn.active, :not(.btn-check) + .btn:active {

	border-color:transparent;
}
.first .row .col p {
  text-align: left;

}

.list {
	color: white;
	font-size: 18px;
	text-align: left;
	margin-bottom: 11px;
	display: inline-block;
	text-decoration: none;
	/* word-break: break-all; */
	overflow-wrap: break-word;
	border-bottom: 1px dashed transparent ;
}
.list:hover {
  border-bottom: 1px dashed white ;
}

.BrowseJobstabs {
	margin-top: 40px; text-align: left;
}

/* JobCard */

.Jcard {
	min-height: 280px;
	position: relative;
	text-align: start;
	border: 1px solid #bcbcbc;
	border-radius: 20px;
	padding: 30px;
  -webkit-transition: 0.5s;
-o-transition: 0.5s;
-moz-transition: 0.5s;
transition: 0.5s; margin-bottom: 25px;
}
.Jcard .JcardBody {
	padding: 0;
}

.shareNodeJobCard {
    position: absolute;
    right: 14px;
    top: 31px;
    font-size: 19px;
}
.JcardTitle {
    border-bottom: 1px solid transparent;
    color: #25242a;
    font-size: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 94%;
}

.Jcard:hover {
	box-shadow: 0 10px 20px 0 rgba(173,173,173,0.32);
	border: 1px solid #f8edea;
	background: #fffbf9;
}

.JcardTitle {
	font-size: 25px;
	color: #25242a;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: 1px transparent solid;
	white-space: nowrap;
}

.JcardBodySection1 {
	color: #33335a;
	font-size: 17px;
	font-weight: 500; opacity: 0.8;
}

.JcardBodySection2 {
	gap: 12px;
	display: flex;
  	margin: 20px 0;
}

.section2Item1 {
	font-size: 14px;
	border: 1px solid #78788d;
	border-radius: 15px;
	padding: 2px 15px 2px 15px;
	display: inline-block;
	color: #6e6e85;
	font-weight: 500;
}
.section2Item2 {
	font-size: 15px;
	display: inline-block;
	color: #6e6e85;
	font-weight: 500;
}
.section2Item2 i {
	font-weight: normal;
	font-size: 20px;
	color: #9797a6;
}
.JcardBodySection3 {
	color: #6e6e85;
	font-size: 17px;
	font-weight: 500;
	min-height: 70px;
}
.card-footer.JcardFooter {
	border-radius: 0;
	background: transparent;
	border: none;
	border-top: 1px #ececec solid;padding: 20px 0 0;
}
.JcardFooter {
  font-size: 13px;
}

.JcardFooterItem1 {
 display: inline-block;
}
.JcardFooterItem1DynamicImage {
	align-items: center;
	display: inline-flex;
	justify-content: center;
}
.JcardFooterItem1DynamicImage img {
	max-width: 70px;
	max-height: 41px;
}
.JcardFooterItem2 {
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.JcardFooterItem2 h6 {
	font-size: 18px;
	color: #25242a;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.JcardFooterItem2 p {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.JcardFooterItem3 {
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;
}

/* Barometer Page */

.barometerPage {
	line-height: 140%;
}
.barometerPage .hero {
	display: flex;
	height: 360px;
	align-items: start;
	flex-direction: column;
	/* justify-content: center; */
	background: url('/public/Images/barometer/Banner.jpg') center;
	background-size: cover;
}
.barometerPage .hero h6 {
	margin-top: 40px;
	margin-bottom: 60px;
	color: #FFFFFF !important;
}
.barometerPage h1 {
	font-size: 45px;
	color: #FFFFFF;
	font-weight: 500;
	max-width: 630px;
	line-height: 140%;
}
.barometerPage h2 {
	font-size: 36px;
	color: #D71920;
	font-weight: 500;
	margin-bottom: 36px;
}
.barometerPage p {
	font-size: 18px;
	font-weight: 300;
	line-height: 140%;
}
.barometerPage .boldSpan {
	font-weight: 500;
}
.barometerPage .redSpan {
	color: #D71920;
	font-weight: 500;
}
.barometerPage .aboutProject {
	margin-top: 80px;
	margin-bottom: 60px;
	scroll-margin-top: 120px;
}
.barometerPage .aboutProject li {
	font-size: 18px;
	font-weight: 500;
	line-height: 140%;
	margin-bottom: 16px;
	text-align: justify;
}
.barometerPage .aboutProject a {
	display: inline-block;
}
.barometerPage .aboutProject p {
	text-align: justify;
}
.barometerPage .aboutProject button {
	font-size: 20px;
	font-weight: 500;
	line-height: 140%;
}
.barometerPage .aboutProject .linkBox {
	padding-left: 24px;
	max-width: fit-content;
	border-left: 3px solid #1D3176;
}
.barometerPage .aboutProject .linkBox:hover {
	text-decoration: underline;
}
.barometerPage .aboutProject .linkBox p {
	font-size: 18px;
	color: #1D3176;
	font-weight: 500;
	margin-bottom: 16px;
}
.barometerPage .aboutProject .linkBox p span {
	font-size: 24px;
}
.barometerPage .timingSection {
	margin-bottom: 80px;
}
.barometerPage .timingSection .timingBox {
	display: flex;
	position: relative;
}
.barometerPage .timingSection .timingBox .periodBox {
	width: 100%;
	height: 795px;
	max-width: 264px;
	background: url('/public/Images/barometer/timeline.png') right no-repeat;
	background-size: contain;
}
.barometerPage .timingSection .timingBox .periodBox p {
	font-size: 22px;
	font-weight: 500;
	line-height: 135%;
}
.barometerPage .timingSection .timingBox .periodBox .firstDate {
	margin-top: 68px;
	margin-bottom: 170px;
}
.barometerPage .timingSection .timingBox .periodBox .secondDate {
	margin-bottom: 142px;
}
.barometerPage .timingSection .timingBox .periodBox .thirdDate {
	margin-bottom: 48px;
}
.barometerPage .timingSection .timingBox .periodBox .fourthDate {
	margin-bottom: 58px;
}
.barometerPage .timingSection .timingBox .stageDescrBox {
	padding-left: 32px;
}
.barometerPage .timingSection .timingBox .stageDescrBox p {
	font-size: 20px;
	font-weight: 500;
	line-height: 135%;
}
.barometerPage .timingSection .timingBox .stageDescrBox ul li {
	font-size: 20px;
	font-weight: 300;
	line-height: 135%;
}
.barometerPage .timingSection .timingBox .stageDescrBox .firstStage {
	margin-top: 72px;
	margin-bottom: 52px;
}
.barometerPage .timingSection .timingBox .stageDescrBox .secondStage {
	margin-bottom: 32px;
}
.barometerPage .timingSection .timingBox .stageDescrBox .thirdStage {
	margin-bottom: 56px;
}
.barometerPage .timingSection .timingBox .stageDescrBox .fourthStage {
	margin-bottom: 50px;
}
.barometerPage .timingSection .timingBox .stageDescrBox .fifthStage span {
	font-weight: 300;
}
.barometerPage .partnersSection {
	padding-top: 72px;
	margin-bottom: 80px;
	padding-bottom: 72px;
	scroll-margin-top: 80px;
	background: url('/public/Images/mainBanner/mainSecondSection.jpg') center;
	background-size: cover;
}
.barometerPage .partnersSection h2 {
	color: #FFFFFF;
	text-align: center;
	margin-bottom: 48px;
}
.barometerPage .partnersSection h3 {
	font-size: 28px;
	color: #C6D9F5;
	text-align: center;
	margin-bottom: 16px;
}
.barometerPage .partnersSection p {
	font-size: 16px;
	color: #FFFFFF;
	font-weight: 500;
	line-height: 140%;
	text-align: justify;
}
.barometerPage .partnersSection .generalPartnerBox {
	gap: 60px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 60px;
}
.barometerPage .partnersSection .generalPartnerBox .partnersCard {
	max-width: 590px;
}
.barometerPage .partnersSection .generalPartnerBox .partnersCard img {
	margin-bottom: 24px;
}
.barometerPage .partnersSection .generalPartnerBox .partnersCard h3 {
	font-size: 20px;
	text-align: left;
}
.barometerPage .partnersSection .partnersBox {
	gap: 60px;
	display: flex;
	flex-wrap: wrap;
}
.barometerPage .partnersSection .partnersBox .partnersCard {
	max-width: 590px;
}
.barometerPage .partnersSection .partnersBox .partnersCard img {
	margin-bottom: 24px;
}
.barometerPage .partnersSection .partnersBox .partnersCard h3 {
	font-size: 20px;
	text-align: left;
}
.barometerPage .partnersSection .partnersBox .partnersCard ul {
	color: #FFFFFF;
	font-weight: 500;
}
.barometerPage .partnersSection .partnersBox .partnersCard a {
	color: #FFFFFF;
	font-weight: 500;
	text-decoration: underline;
}
.barometerPage .aboutSection {
	margin-bottom: 80px;
	scroll-margin-top: 120px;
}
.barometerPage .aboutSection p{
	text-align: justify;
}
.barometerPage .invitationSection {
	padding-top: 72px;
	margin-bottom: 80px;
	padding-bottom: 72px;
	background: url('/public/Images/mainBanner/mainSecondSection.jpg') center;
	background-size: cover;
}
.barometerPage .invitationSection h2 {
	color: #FFFFFF;
	margin-bottom: 32px;
}
.barometerPage .invitationSection p {
	font-size: 20px;
	color: #FFFFFF;
	font-weight: 500;
	text-align: justify;
}
.barometerPage .invitationSection .invitationBox {
	gap: 60px;
	display: flex;
	align-items: center;
}
.barometerPage .invitationSection .invitationBox .invitationDescr {
	max-width: 636px;
}
.barometerPage .invitationSection .invitationBox .highlighted {
	color: #BBCFED;
	font-weight: 500;
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
	.barometerPage .partnersSection .generalPartnerBox .partnersCard {
		max-width: 540px;
	}
	.barometerPage .partnersSection .partnersBox {
		gap: 30px;
	}
	.barometerPage .partnersSection .partnersBox .partnersCard {
		max-width: 540px;
	}
}
@media screen and (max-width: 767px) {
	.barometerPage h2 {
		font-size: 28px;
		color: #D71920;
		font-weight: 500;
		margin-bottom: 36px;
	}
	.barometerPage p {
		font-size: 18px;
		font-weight: 300;
		line-height: 140%;
	}
	.barometerPage .hero h6 {
		margin-top: 20px;
		margin-bottom: 40px;
	}
	.barometerPage .aboutProject,
	.barometerPage .aboutSection {
		scroll-margin-top: 40px;
	}
	.barometerPage .partnersSection {
		scroll-margin-top: 0px;
	}
	.barometerPage .aboutProject {
		margin-top: 40px;
		margin-bottom: 30px;
	}
	.barometerPage .aboutProject li {
		font-size: 18px;
	}
	.barometerPage .aboutProject button {
		font-size: 20px;
	}
	.barometerPage .timingSection {
		margin-bottom: 40px;
	}
	.barometerPage .timingSection .timingBox .timingMobile {
		padding-left: 16px;
	}
	.barometerPage .timingSection .timingBox .timingMobile p {
		font-size: 18px;
	}
	.barometerPage .timingSection .timingBox .timingMobile li {
		font-weight: 300;
		position: relative;
	}
	.barometerPage .timingSection .timingBox .timingMobile .firstStage {
		margin-top: 56px;
		margin-bottom: 16px;
	}
	.barometerPage .timingSection .timingBox .timingMobile .secondStage {
		top: 280px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .thirdStage {
		top: 480px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .fourthStage {
		top: 590px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .fifthStage {
		top: 696px;
		position: absolute;
	}
	.barometerPage .partnersSection {
		padding-top: 40px;
		margin-bottom: 40px;
		padding-bottom: 40px;
	}
	.barometerPage .partnersSection .partnersBox {
		gap: 40px;
	}
	.barometerPage .aboutSection {
		margin-bottom: 40px;
	}
	.barometerPage .invitationSection {
		padding-top: 40px;
		margin-bottom: 40px;
		padding-bottom: 40px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	.barometerPage .timingSection .timingBox .timingMobile {
		padding-left: 16px;
	}
	.barometerPage .timingSection .timingBox .timingMobile p {
		font-size: 18px;
	}
	.barometerPage .timingSection .timingBox .timingMobile li {
		font-weight: 300;
		position: relative;
	}
	.barometerPage .timingSection .timingBox .timingMobile .firstStage {
		margin-top: 56px;
		margin-bottom: 16px;
	}
	.barometerPage .timingSection .timingBox .timingMobile .secondStage {
		top: 280px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .thirdStage {
		top: 480px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .fourthStage {
		top: 590px;
		position: absolute;
	}
	.barometerPage .timingSection .timingBox .timingMobile .fifthStage {
		top: 696px;
		position: absolute;
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.barometerPage .timingSection .timingBox .stageDescrBox p {
		font-size: 20px;
	}
	.barometerPage .timingSection .timingBox .stageDescrBox ul li {
		font-size: 20px;
	}
	.barometerPage .timingSection .timingBox .stageDescrBox .secondStage {
		margin-bottom: 36px;
	}
	.barometerPage .timingSection .timingBox .stageDescrBox .thirdStage,
	.barometerPage .timingSection .timingBox .stageDescrBox .fourthStage {
		margin-bottom: 50px;
	}
}
@media screen and (max-width: 1199px) {
	.barometerPage .invitationSection .invitationBox {
		flex-wrap: wrap;
	}
}

/* Anketa */
.anketaPage {
	height: calc(100vh - 272px);
}
.anketaPage .bannerBox {
	display: flex;
	margin-top: 12px;
	margin-bottom: 12px;
	justify-content: center;
}
.anketaPage img {
	border-radius: 8px;
}
@media screen and (max-width: 768px) {
	.anketaPage .bannerBox {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@media screen and (min-width: 768px) {
	.anketaPage img {
		margin-right: 12px;
	}
}

/* Footer */

/* Display the li elements when the mobile menu is open */
.footer.mobile-menu-open ul.mobile-menu {
	display: block;
}

  /* Hide the li elements when the mobile menu is closed */
.footer ul.mobile-menu {
	display: none;
}
.footerHeadersRow li {
	color: white;
	font-size: 17px;
	font-weight: 300;
}
.FooterLinks .assistanceLinks a {
	text-decoration: underline;
}
.FooterLinks .contractsLinks a {
	max-width: 260px;
	display: inline-block;
}
.FooterLinks .contractsLinks a:hover {
	text-decoration: underline !important;
}
.FooterLinks .quickLinks i {
	margin-right: 8px;
}
.FooterLinks .quickLinks a:hover {
	text-decoration: underline !important;
}
.footerEachHeader {
	width: 100%;
}
.footerEachHeader h3 {
	display: inline-block;
}
.footerPlusLink {
	color: white;
	/* padding: 0px 0 14px 0px; */
	font-size: 25px;
	float: right;
	margin-bottom: 35px;
}
.footer {
	padding-top: 30px;
	background-color: #12233c;
}
.footer li {
	list-style: none;
}
.FooterLinks h3 {
	color: #fff;
	font-size: 26px;
	font-weight: 500;
}
.FooterLinks ul {
	margin-top: 15px;
}
.FooterLinks ul li {
	margin-top: 10px;
}
.FooterLinks ul li a {
	color: #fff;
	font-size: 17px;
	font-weight: 300;
	text-decoration: none;
}
.footerHeadersCol {
  color: white;
  margin-top: 5%;
  margin-bottom: 1%;
  font-size: 23px;
  font-weight: bold;
  text-align: start;
  margin-left: 7%;
  /* margin-right: 7%; */
}

.footerBodyCol {
  color: white;
  font-size: small;
  margin-top: 2%;
  text-align: start;
  margin-left: 7%;
  margin-right: 7%;
  text-decoration: none;
}

.footerFooter {
  	color: white;
  	margin-top: 30px;
}
.footerFooter hr {
	margin: 0;
}
.footerFooter p {
	font-size: 17px;
	padding: 20px 0;
	text-align: center;
}
.footerFooter a {
	color: white;
	text-decoration: none;
}
.footer .iconLinks {
	margin-top: 10px;
}
.footer .iconLinks a {
	font-size: 24px;
	margin-right: 20px;
}
.SocialIcons {
	margin-top: 15px;
}
.SocialIcons a {
	width: 36px;
	height: 36px;
	color: #fff;
	line-height: 33px;
	border-radius: 50%;
	margin-right: 10px;
	text-align: center;
	display: inline-block;
	border: 2px #fff solid;
}
.MembershipMainBx {
	margin-top: 60px;
	border-left: 2px solid rgba(222,222,222,0.5);
	border-right: 2px solid rgba(222,222,222,0.5);
}
.MembershipCard {
    padding: 30px 70px;
}
.MembershipCard li {
    list-style: none;
}
.MembershipCardAuth {
    padding: 30px 5px;
}
.membershipCardUpperPart {
	min-height: 190px;
}
.membershipCardMain {
	height: 100%;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.membershipCardMain:hover{
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MembershipCard h4 {
	color: #25242a;
	font-size: 25px;
	font-weight: 500;
}

.MembershipCard h2 {
	margin: 20px 0 0;
	font-size: 50px;
	font-weight: 600;
	color: #C35656;
}
.MembershipCard h6 {
	color: #25242a;
	font-size: 17px;
	opacity: 0.8;
	font-weight: normal;
}
.ApplyAll {
	border-top: 2px solid rgba(222,222,222,0.5);
	margin-top: 20px;
	padding: 23px 20px 0;
	text-align: left;
	margin-right: 35px;
}
.ApplyAll .form-check-input[type="checkbox"] {
	border-radius: 2px;
	border-width: 2px;
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.ApplyAll .form-check-label {
	color: #25242a;
	font-size: 15px;
	opacity: 0.7;
	padding-top: 2px;
}
.applyoption {
	padding: 23px 0 0 24px;
	text-align: left;
}
.applyoption li {
	position: relative;
	color: #C35656;
	font-size: 17px;
	/* background: url("/public/Images/BlueArrow.png") no-repeat left center; */
	padding: 0 0 0 0px;
	margin: 9px 0;
}
.membershipChecks {
	color: #C35656;
	padding-right: 12px;

}
.MembershipCard .btn-primary {
	background: #C35656;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 10px 32px;
	width: 84%;
	margin-top: 30px;
}
.MembershipCard .btn-secondary {
    background: #294a9c;
    border: none;
    border-radius: 3px;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding: 10px 32px;
    width: 84%;
    margin-top: 30px;
}
.center .MembershipCard h2{color: #294a9c;}
.center .applyoption li {
	/* background: url("/public/Images/OrengeArrow.png") no-repeat left center; */
	color: #294a9c;
}
.center .membershipChecks {
	color: #294a9c;
	padding-right: 12px;

}
.center .MembershipCard .btn-primary {
	background: #294a9c;
}
.center .MembershipCard .btn-primary:hover {
	background: #294a9c;
	box-shadow: 0 10px 20px 0 rgba(243,115,76,0.5);
}
.center .MembershipCard{ background-color: #fffcfb;
	border-left: 2px solid rgba(222,222,222,0.5);
	border-right: 2px solid rgba(222,222,222,0.5);
}
.MembershipMainBx .owl-dots {
	display: none;
}
.MembershipMainBx .owl-nav {
	position: absolute;
	margin: 0;
	left: 0;
	top: 50%;
	transform: translate(0,-50%);
	width: 100%;
}
.MembershipMainBx .owl-nav .owl-prev {
	margin: 0;
	position: absolute;
	left: -60px; background: transparent !important;
}
.MembershipMainBx .owl-nav .owl-next span,
.MembershipMainBx .owl-nav .owl-prev span {
	margin: 0;
	border: 1px #7e7d7f solid !important;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	font-size: 35px;
	line-height: 32px;
	display: inline-block;
	color: #7e7d7f;
}
.MembershipMainBx .owl-nav .owl-next {
	margin: 0;
	position: absolute;
	right: -60px; background: transparent !important;
}
.center .MembershipCard {
	box-shadow: none;
	-webkit-transition: all 200ms ease-in;
	-webkit-transform: scale(1.10);
	-ms-transition: all 200ms ease-in;
	-ms-transform: scale(1.10);
	-moz-transition: all 200ms ease-in;
	-moz-transform: scale(1.10);
	transition: all 200ms ease-in;
	transform: scale(1.10);
}
.MembershipSection {
	padding: 40px 0;
}
.bodyItem:hover .SidebarImages img,
.imageChild2 {-webkit-animation: balloon2 3s ease-in-out infinite;-moz-animation: balloon2 3s ease-in-out infinite;
	-o-animation: balloon2 3s ease-in-out infinite;animation: balloon2 3s ease-in-out infinite; }
   @-webkit-keyframes balloon2 {
	   0%,
	   100% {
		 -webkit-transform: translateY(0) rotate(0eg);
	   }
	   50% {
		 -webkit-transform: translateY(-10px) rotate(0deg);
	   }
	 }
	 @-moz-keyframes balloon2 {
	   0%,
	   100% {
		 -moz-transform: translateY(0) rotate(0deg);
	   }
	   50% {
		 -moz-transform: translateY(-10px) rotate(-0deg);
	   }
	 }
	 @-o-keyframes balloon2 {
	   0%,
	   100% {
		 -o-transform: translateY(0) rotate(0deg);
	   }
	   50% {
		 -o-transform: translateY(-10px) rotate(0deg);
	   }
	 }
	 @keyframes balloon2 {
	   0%,
	   100% {
		 transform: translateY(0) rotate(0deg);
	   }
	   50% {
		 transform: translateY(-10px) rotate(0deg);
	   }
	 }


	 .CategoryCard2 .imageChild2 {
		left: 24px;
	}
	.HomeCategorysCardBx:nth-child(7) .imageChild2,
	.HomeCategorysCardBx:nth-child(5) .imageChild2,
	.HomeCategorysCardBx:nth-child(4) .imageChild2,
	.HomeCategorysCardBx:nth-child(2) .imageChild2,
	 .CategoryCard2 .imageChild2 {-webkit-animation: balloon3 3s ease-in-out infinite;-moz-animation: balloon3 3s ease-in-out infinite;
		-o-animation: balloon3 3s ease-in-out infinite;animation: balloon3 3s ease-in-out infinite; }
	   @-webkit-keyframes balloon3 {
		   0%,
		   100% {
			 -webkit-transform: translateX(0) rotate(0eg);
		   }
		   50% {
			 -webkit-transform: translateX(-10px) rotate(0deg);
		   }
		 }
		 @-moz-keyframes balloon3 {
		   0%,
		   100% {
			 -moz-transform: translateX(0) rotate(0deg);
		   }
		   50% {
			 -moz-transform: translateX(-10px) rotate(-0deg);
		   }
		 }
		 @-o-keyframes balloon3 {
		   0%,
		   100% {
			 -o-transform: translateX(0) rotate(0deg);
		   }
		   50% {
			 -o-transform: translateX(-10px) rotate(0deg);
		   }
		 }
		 @keyframes balloon3 {
		   0%,
		   100% {
			 transform: translateX(0) rotate(0deg);
		   }
		   50% {
			 transform: translateX(-10px) rotate(0deg);
		   }
		 }
/* For Employers */
.forEmployersSection2 p {
	font-size: 18px;
}
.forEmployersSection2 a {
	display: inline-block;
}
.forEmloyersRates {
	text-align: center;
	margin-bottom: 60px;
}
.forEmloyersRatesBox {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 60px;
	justify-content: center;
}
.forEmloyersRatesBox .MembershipCardAuth {
	width: 300px;
	box-shadow: 0 3px 8px #0000003d;
}
.forEmloyersDesc {
	border-radius: 15px;
	margin-bottom: 24px;
	padding: 102px 40px;
	border: 1px solid rgba(0, 0, 0, 0.08);
}
.forEmloyersIcon {
	width: 74px;
	height: 46px;
	margin-right: 12px;
}
@media screen and (min-width: 768px) and (max-width: 1399px) {
	.forEmloyersDesc {
		padding: 54px 40px;
	}
}
@media screen and (max-width: 767px) {
	.forEmployersSection2 p {
		font-size: 14px;
	}
	.forEmloyersDesc {
		padding: 60px 12px;
	}
}
/* About us */
.aboutusSection1 {
	display: flex;
	padding: 70px 0;
	margin: 0 0 60px 0;
	align-items: center;
	flex-direction: column;
  	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}
.aboutusSection2 img {
  	width: 100%;
}
.aboutusImage {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	border-radius: 8px;
}
.aboutusImage:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.06);
}
.aboutusSection2content {
  margin: 5% 0 5% 0;
}
.aboutusSection3 .row {
  margin: 5% 0 2% 0;
}
.aboutusSection3 h1{
	color: #f3734c;
	font-size: 50px;
	font-weight: 600;
}
.aboutusSection3Col h2 {
	font-size: 50px;
	color: #294a9c;
	font-weight: 600;
}
.aboutusSection3Col p {
	margin: auto;
}
.aboutusSection4 h2 {
  max-width: 784px;
}
.aboutusSection4 .card {
	min-height: 300px;
	margin: 0 3% 5% 3%;
	transition: all 1s ease;
	border: 1px solid rgba(0,0,0,0.08);
	border-radius: 10px;
}
.aboutusSection4 .card:hover {
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	transition: all 1s ease;
}
.aboutUsCardImage1 {
  padding-top: 5%;
  position: absolute;
  z-index: 2;
}
.aboutUsCardImage2 {
  padding-top: 11%;
  position: relative;
  z-index: 1;
}
.postBox {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}
/* For Jobseekers */
.companiesLogoBx {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}
.companiesLogo {
	margin-bottom: 50px;
	max-width: fit-content;
	min-width: fit-content;
}
.forJobseekersCardBx {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;
}
.forJobseekersCardBx .card {
	margin: 0 5px 5px;
	min-height: 300px;
	border-radius: 10px;
	transition: all 1s ease;
	border: 1px solid rgba(0,0,0,0.08);
}
.forJobseekersCardBx .card1 {
	width: 290px
}
.forJobseekersCardBx a {
	font-size: 18px;
}
.JSEPFirstSegment {
	gap: 24px;
	display: flex;
	flex-wrap: wrap;
	margin: 36px 0;
	align-items: center;
}
@media screen and (min-width: 992px) {
	.JSEPFirstSegment {
		margin: 36px 24px;
	}
}

/* Jobseeker listing */

.jobseekerListing .mainSearchSection {
	height: 175px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}
.mainSearchSection form {
	gap: 8px;
	width: 100%;
	height: 50px;
	margin: auto;
	display: flex;
	max-width: 788px;
	align-items: center;
	justify-content: center;
}
.mainSearchBox {
	gap: 8px;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 12px 8px;
	background: #FFF;
	border-radius: 5px;
	align-items: center;
	border: 1px solid #BCBCBC;
}
.mainSearchBox input {
	width: 100%;
	border: none;
	outline: none;
	font-size: 16px;
	color: #333333;
}
.jobseekerListing .jobseekerSearchResults {
	padding-top: 24px;
	padding-bottom: 80px;
}
.titleBar p {
	font-size: 16px;
	color: #333333;
	line-height: 115%;
	margin-bottom: 12px;
}
.titleBar span {
	color: #333333;
	font-weight: 600;
	line-height: 115%;
}
.titleBar h1 {
	font-size: 24px;
	color: #333333;
	font-weight: 500;
	margin-bottom: 24px;
}
.selectedSearchFiltersBar {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
}
.filterTag {
	gap: 12px;
	display: flex;
	color: #333333;
	line-height: 115%;
	padding: 6px 12px;
	align-items: center;
	border-radius: 20px;
	border: 1px solid #797979;
}
.filterTag p {
	cursor: default;
}
.filterTag button {
	padding: 0;
	border: none;
	background: none;
	color: #797979;
}
.selectedSearchFiltersBar .advancedSearchButtons {
	margin-bottom: 0;
}
.searchResultsList {
	gap: 24px;
	display: flex;
	flex-direction: column;
}
.searchResultsList a {
	color: #333333;
}
.searchResultsList .sortDropdownBox {
	display: flex;
	align-items: center;
	justify-content: end;
}
.searchResultsList .sortLabel {
	font-weight: 500;
	margin-right: 8px;
}
.searchResultsList .sortSelect {
	height: 38px;
	outline: none;
	padding: 2px 8px;
	border-radius: 5px;
	border: 1px solid #BCBCBC;
}
.searchResultsList .pagination {
	display: flex;
	justify-content: center;
}
.advancedSearchSection h2 {
	font-size: 20px;
	color: #333333;
	font-weight: 500;
	margin-bottom: 20px;
}
.advancedSearchSection .showFilterSectionBtn {
	padding: 0;
	width: 100%;
	border: none;
	display: flex;
	font-size: 20px;
	background: none;
	color: #333333;
	font-weight: 500;
	margin-bottom: 20px;
}
.advancedSearchSection p {
	font-size: 16px;
	color: #333333;
	font-weight: 500;
	margin-bottom: 20px;
}
.advancedSearchButtons {
	display: flex;
	margin-bottom: 20px;
	justify-content: space-between;
}
.advancedSearchButtons button {
	border: none;
	font-size: 16px;
	background: none;
	color: #FF0B0F;
}
.advancedSearchButtons button:disabled {
	opacity: 0.7;
}
.advancedSearchButtons button:not(:disabled):hover {
	text-decoration: underline;
}
.advancedSearchButtons button i {
	margin-right: 8px;
}
.paramsBox {
	margin-bottom: 20px;
	border-bottom: 2px solid #F4F3F3;
}
.paramsBox p {
	margin-bottom: 12px;
}
.paramsBox .text-danger {
	font-size: 14px;
}
.searchLogicBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchLogicBox .radioLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchLogicBox .radioLabel input {
	width: 16px;
    height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.searchPlaceBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchPlaceBox .checkboxLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchPlaceBox .checkboxLabel input {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.regionParamsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.categoryParamsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchSalaryBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchSalaryBox input {
	height: 38px;
	padding: 2px 8px;
	border-radius: 5px;
	border: 1px solid #BCBCBC;
}
.searchSalaryBox select {
	height: 38px;
	padding: 2px 8px;
	border-radius: 5px;
	border: 1px solid #BCBCBC;
}
.searchExperienceBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchExperienceBox .checkboxLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchExperienceBox .checkboxLabel input {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.industryParamsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchWorkTypeBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchWorkTypeBox .checkboxLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchWorkTypeBox .checkboxLabel input {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.educationParamsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.languageParamsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchAgeBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchAgeBox input {
	height: 38px;
	padding: 2px 8px;
	border-radius: 5px;
	border: 1px solid #BCBCBC;
}
.searchAgeBox select {
	height: 38px;
	padding: 2px 8px;
	border-radius: 5px;
	border: 1px solid #BCBCBC;
}
.searchGenderBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchGenderBox .checkboxLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchGenderBox .checkboxLabel input {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.searchDriverLicenseBox {
	gap: 8px;
	display: flex;
	margin-bottom: 20px;
	flex-direction: column;
}
.searchDriverLicenseBox .checkboxLabel {
	display: flex;
    cursor: pointer;
    align-items: center;
}
.searchDriverLicenseBox .checkboxLabel input {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	accent-color: #FF0B0F;
}
@media screen and (max-width: 991px) {
	.advancedSearchButtons {
		margin-bottom: 0;
	}
}

/* cvCard */

.cvCard {
	padding: 20px;
	font-size: 16px;
	line-height: 115%;
	border-radius: 20px;
	border: 1px solid #BCBCBC;
}
.cvCard h2 {
	font-size: 24px;
	font-weight: 500;
	line-height: 125%;
	margin-bottom: 12px;
}
.cvCard h3 {
	font-size: 20px;
	color: #797979;
	font-weight: 600;
}
.cvCard h4 {
	font-size: 16px;
	color: #838285;
	font-weight: 500;
}
.mainInfoBlock {
	gap: 20px;
	display: flex;
	padding-bottom: 12px;
	justify-content: space-between;
	border-bottom: 1px solid #ECECEC;
}
.mainInfoBlock .profileImage {
	width: 100px;
	height: 100px;
	border-radius: 16px;
}
.lastWorkPlace {
	gap: 12px;
	display: flex;
	padding: 12px 0;
	margin-bottom: 20px;
	flex-direction: column;
	border-bottom: 1px solid #ECECEC;
}
.lastWorkPlace span {
	font-weight: 500;
}
.lastWorkPlace button {
	border: none;
	font-size: 16px;
	background: none;
	color: #FF0B0F;
	width: fit-content;
}
.lastWorkPlace button:hover {
	text-decoration: underline;
}
.lastWorkPlace button i {
	margin-left: 8px;
}
.lastWorkPlaceBox {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
}
.cvCardFooter {
	gap: 24px;
	display: flex;
	color: #797979;
	font-weight: 500;
	align-items: center;
}
.cvCardFooter i {
	margin-right: 8px;
}
.experienceAge {
	padding: 2px 15px;
	width: fit-content;
	border-radius: 15px;
	border: 1px solid #78788D;
}
.experienceAge {
	color: #797979;
	font-weight: 500;
	padding: 2px 15px;
	width: fit-content;
	border-radius: 15px;
	border: 1px solid #78788D;
}
@media screen and (max-width: 991px) {
	.cvCard h2 {
		font-size: 20px;
	}
	.mainInfoBlock {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
}

/* How it Works */

.PPSection1 h1 {
	color: #25242a;
    font-size: 35px;
    font-weight: 500;
}
.PPSection1 h6{
	margin-top: 10px;
    font-weight: 300;
    font-size: 18px;
    color: rgba(37,36,42,0.9) !important;
}

/* BlogPage */

.blogPageSection1 {
	padding: 70px 0;
	margin: 0 0 3% 0;
	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}
.blogPageSection1 h1 {
	margin: 0 auto;
	font-size: 40px;
    font-weight: 500;
	max-width: 800px;
}
.blogPageSection1 h6 {
    font-weight: 400;
	margin-top: 10px;
	font-size: 18px;
}
.blogPagination {
	text-align: right;
	padding-right: 20px;
}
.paginationDetail {
	margin: 10px 60px 10px 0;
}


/* BlogCard */

.blogCard {
  	min-height: 100%;
  	/* margin-bottom: 2%; */
}

.blogCard img {
	max-height: 320px;
  	border-radius: 15px;
}
.blogCardImageBox {
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (min-width: 768px) {
	.blogCardImageBox {
		height: 320px;
	}
}

.blogCardBody {
  font-weight: 500;
}
.blogCardBody .card-title{
  min-height: 60px;
}

.blogCardBody a{
  text-decoration: none;
  color: #294a9c;
  font-weight: 400;
}
.blogPagination  {
	margin: 15px 0 30px 0;
}
.blogCardFooter {
	border: none;
}
.blogCardFooter a {
	color: #FF0B0F;
}
.blogCardFooter a:hover {
	color: #294A9C;
}

/* Dynamic blog page */

.DBPSection1 {
	margin: 0 0 3% 0;
	padding: 3% 0 3% 0;
	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}
.DBPSection2 {
	margin-bottom: 60px;
}


.DBPSection2 img {
  max-height: 700px;
  padding-bottom: 2%;
  border-radius: 15px;
}
.DBPSection1 h1 {
	font-size: 30px;
    font-weight: 500;
}
.DBPSection1 h6 {
    font-weight: 400;
	margin-top: 10px;
	font-size: 18px;
}

/* faq page */

.faqSection1 {
  background-image: url("/public/Images/FAQ-Slider.png");
  background-repeat: no-repeat;
  padding:70px 0;
  margin: 0 0 3% 0;
}

.faqSection1 h6 {
	margin-bottom: 6%;
}

.faqSection1 .card {
  max-width: 40%;
  font-size: 500;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border: 0;
}

.faqSection2 .error {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	min-width:fit-content;
	border: 1px solid red;
	border-radius: 3px;
}
.faqSection2 .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	min-width:fit-content;
	border: 1px solid red;
	border-radius: 3px;
}

.faqSection1 input {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

.faqSection2 select {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	height: 50px;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
	height: 45px;
}

.faqSection1 textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 19px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

.faqSection1 .card button {
  color: white;
  background-color:#294a9c;
}
.faqSection1 .card button:hover {
  color: white;
  background-color:#f3734c;
}
.faqSection2 {
	margin-top: 25px;
}


/* Contact Page */

.ContactSection1 {
	padding: 70px 0;
	margin: 0 0 3% 0;
	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}

.ContactSection1 h1 {
	margin-bottom: 10px;
}

.ContactSection3 .col-md-9 button {
  border-radius: 2px;
  color: white;
  background-color:#294a9c;
  float: left;
}
.ContactSection3 .col-md-9 button:hover {
  border-radius: 2px;
  color: white;
  background-color:#f3734c;
}

.ContactSection3 input {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	opacity: 0.8;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid rgba(202,202,202,0.6);
	border-radius: 6px;
	height: 48px;
}
.ContactSection3 .error {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 6px;
	height: 48px;
}
.ContactSection3 .input-error {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px solid red;
	border-radius: 6px;
	height: 48px;
}

.ContactSection3 textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 19px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 3px;
}

/* Search job page */

.SJPSection1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-image: url("/public/Images/mainBanner/diamonds.jpeg");
	background-size: cover;
}
.SJPSection1 h1 {
	font-size: 40px;
	margin-top: 50px;
	text-align: center;
	margin-bottom: 10px;
}
.SJPSection1 form {
	width: 100%;
	max-width: 1320px;
}
.SJPSection1 li {
	list-style: none;
}
.SJPSection1 button {
	text-transform: uppercase;
}
.SJPSection1 .formItems{
	width: 100%;
	display: flex;
	max-width: 1320px;
	padding: 25px 35px;
	align-items: center;
	border-radius: 15px;
	margin-bottom: 50px;
}
.formItems input {
	width: 100%;
	height: 50px;
	font-size: 16px;
	color: #797979;
	min-width: fit-content;
	border: 1px solid #bcbcbc;
	box-shadow: none !important;
	min-width: -moz-fit-content;
}
.formItems select {
	width: 100%;
	height: 50px;
	font-size: 16px;
	color: #797979;
	border: 1px solid #bcbcbc;
	box-shadow: none !important;
}
.suggestions {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2px;
    padding: 10px;
    position: absolute;
    top: 69px;
    width: -webkit-fill-available;
    z-index: 99;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.suggestionsSmall {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2px;
    padding: 10px;
    position: absolute;
    top: 49px;
    width: -webkit-fill-available;
    z-index: 99;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.suggestionsSmall li {
	padding: 5px;
	cursor: pointer;
	list-style: none;
}
.suggestionsSmall li:hover {
	background-color: #FEEAE4;
	border-radius: 8px;
}
.suggestions li {
	padding: 5px;
	cursor: pointer;
}
.suggestions li:hover {
	background-color: #FEEAE4;
	border-radius: 8px;
}

.SJPSection2 {
	margin-bottom: 3%;
}
.SJPSection2 .titleBox {
	display: flex;
	justify-content: space-between;
}
.SJPSection2 .titleBox h1 {
	font-size: 16px;
	font-weight: 600;
	margin-top: 25px;
	line-height: 150%;
	margin-bottom: 30px;
}
.eachLocation {
	display: flex;
}
.locationIcon {
	margin: 0 8px 0 0;
}
@media screen and (max-width: 767px) {
	.SJPSection1 {
		background: url("/public/Images/mainBanner/diamonds.jpeg") top right;
		background-size: cover;
	}
	.SJPSection2 .titleBox {
		flex-direction: column;
	}
	.SJPSection2 .titleBox h1 {
		margin-bottom: 0px;
		text-align: center;
	}
}

/* Search by regions */

.searchByRegionsBox {
	margin-top: 20px;
}
.searchByRegionsBox h2 {
	font-size: 28px;
	margin-bottom: 20px;
}
.searchByRegionsBox a {
	font-size: 18px;
	color: #294A9C;
}
.searchByRegionsBox a:hover {
	text-decoration: underline !important;
}

/* All category Page */

.allCategorySection2 .eachLink {
	margin-top: 25px;
	color: black;
}
.eachLink:hover {
	text-decoration: underline !important;
}

/* Company profile */

.companyProfileCardLeft {
    padding: 30px;
    text-align: start;
    position: relative;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 1px solid #f6ac96;
}
.companyProfileCardLeft h4 {
    margin-right: 8px;
}

.companyProfileCardRight {
	display: flex;
	height: 280px;
    padding: 20px;
	align-items: center;
    border-radius: 10px;
	margin-bottom: 25px;
	justify-content: center;
    border: 1px solid #f6ac96;
}
.companyProfileCardRightImage {
	margin: auto;
	max-width: 90%;
	border-radius: 5px;
}

.CPThirdSection {
	width: 100%;
	margin-top: 20px;
}

.CPTPart1 {
	display: inline-block;
	margin-right: 63px;
}
.CPTPart2 {
	display: inline-block;
}
.CPFourthSection {
	width: 100%;
	margin-top: 20px;
}
.CPFPart1 {
	display: inline-block;
	margin-right: 80px;
}
.CPFPart2 {
	display: inline-block;
}
.CPFifthSection {
	width: 100%;
	margin-top: 20px;
}
.CPFifthPart1 {
	display: inline-block;
	margin-right: 58px;
}
.CPFifthPart2 {
	display: inline-block;
}
.CPSixthSection {
	width: 100%;
	margin-top: 40px;
}
.CPSPart1 {
	display: inline-block;
	margin-right: 62px;
	margin-bottom: 20px;
}
.CPSPart2 {
	display: inline-block;
}
.companyProfileCardFooter {
	margin-top: 70px;
	width: 100%;
	display: inline-block;

}

.companyProfileCardFooter .companyProfileLocation {
	font-weight: 400;
	margin-bottom: 10px;
}
.companyProfileCardFooter .companyProfileContact {
	font-weight: 400;
}

/* Privacy Policy */

.privacyPolicy {
	padding-bottom: 70px;
}

.privacyPolicy .card {
	max-width: 100%;
	font-size: 500;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
	border: 0;
}

.privacyPolicy .error {
	box-shadow: none !important;
    outline: none;
    font-size: 17px;
    height: 45px;
    font-weight: 400;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border: 1px solid red;
    border-radius: 3px;
}
.privacyPolicy .input-error {
	box-shadow: none !important;
    outline: none;
    font-size: 17px;
    height: 45px;
    font-weight: 400;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border: 1px solid red;
    border-radius: 3px;
}
.privacyPolicy input {
    box-shadow: none !important;
    outline: none;
    font-size: 17px;
    height: 45px;
    font-weight: 400;
    opacity: 0.8;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border: 1px solid #bcbcbc;
    border-radius: 3px;
}

.privacyPolicy select {
	box-shadow: none !important;
	outline: none;
    font-size: 17px;
    height: 45px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid #bcbcbc;
	border-radius: 3px;
	height: 45px;
}

.privacyPolicy textarea {
	box-shadow: none !important;
	outline: none;
	font-size: 18px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px solid #bcbcbc;
	border-radius: 3px;
}

/* Employer login */

.employerLogin .card {
	box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
	border: none;
	margin: 10% 5% 10% 5%;
}

.employerLogin .leftSection img {
	object-fit:cover;
	width: 100%;
	height: 100%;
}

.employerLogin form {
	padding: 0 13% 0 13%;
}

.employerLogin input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.employerLogin input:hover {
	border: 1px dashed #f3734c;
	box-shadow:10px 14px 22px rgba(246,172,150,0.3);
}
.passwordBox {
	position: relative;
}
.passwordVisibility  {
	position: absolute;
    right: 13px;
    top: 11px;
}
.forgotPassword {
	width: 100%;
	margin-bottom: 45px;
}
.forgotPassword a{
	float: right;
}

/* Jobseeker login */
.jobseekerLogin .card {
	box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
	border: none;
	margin: 10% 5% 10% 5%;

}

.jobseekerLogin .leftSection img {
	height: 100%;
	object-fit:cover;
	width: 100%;
}

.jobseekerLogin form {
	padding: 0 13% 0 13%;
}

.jobseekerLogin input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 50px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.jobseekerLogin input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 10px 14px 22px rgba(246,172,150,0.3);
}

/* Employers login */
/* Side bar */

.SidebarSection1 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}
.SidebarSection1 li {
	list-style: none;
}
.SidebarSection2 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}
.SidebarSection3 .SBHeader h3 {
	color: black;
	padding-top: 6px;
}

.bodyItem {
	display: block;
	margin: 20px 0;
	color: grey;
}

.reCaptchaLogin {
	height: 78px;
	margin: 20px 0 20px 0;
}


.SidebarImages {
	display: inline-block;
	position: relative;
	margin: 0;
	width: 47px;
	height: 44px;
	text-align: center; vertical-align: middle;
}
.SidebarImages .imageChild1 {
	position: relative;
	z-index: 1;
	top: 0;
	left: 0;
}

.SidebarImages .imageChild2 {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 4px;
}

.menuTitle {
	display: inline-block;
	padding-left: 20px; vertical-align: middle;
}
/* for mobile view */
.sidebarEachHeader {
    width: 100%;
    display: inline-block;
}
.sidebarEachHeader .sidebarPlusLink {
    margin-bottom: 0;
}
.sidebarEachHeader h3 {
	display: inline-block;
}
.sidebarPlusLink {
	color: black;
	/* padding: 0px 0 14px 0px; */
	font-size: 25px;
	float: right;
	/* margin-right: 10px; */
	margin-bottom: 35px;
}

/* Employer Registration */

.EPRegistrationBottomText {
	margin-bottom: 10px;
	text-align: center;
}

/* Forgot Password */
.forgotPasswordCard {
	width: 50%;
	margin: 0px auto;
}
.forgotPasswordProcessTxt {
	margin: 15px 0 20px 0;
	font-weight: bold;
}
/* .forgotPasswordReturnLogin {

} */

/* Create job */

.createJob {
	margin-top: 3%;
}

.createJob h3 {
	color: #f3734c;
}

.createJob input {
	opacity: 0.8;
	outline: none;
	padding: 20px;
	font-size: 17px;
	font-weight: 400;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.createJob .skillPackage input {
	height: 58px;
}
.createJob input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.jobApplySettingsBx {
	outline: none;
	padding: 20px;
	display: block;
	font-size: 17px;
	font-weight: 400;
	border-radius: 6px;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.jobApplySettingsBx:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.jobApplySettingsInputsBx {
	gap: 16px;
	display: flex;
	flex-direction: column;
}
.jobApplySettingsLabel {
	gap: 10px;
	display: flex;
	align-items: center;
}
.jobApplySettingsLabel input {
	opacity: 1;
    width: 20px;
	height: 20px;
	accent-color: #294A9C;
}
.createJob textarea {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
	padding: 5px 20px;
}
.createJob textarea:hover {
	border: 1px dashed #a46cc4;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.createJob select {
	border: none;
	opacity: 0.8;
	outline: none;
	padding: 20px;
	color: black;
	font-size: 17px;
	font-weight: 400;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.createJob select:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}

.createJob .upper {
	width: 100%;
	margin-bottom: 10px;
}
.createJob .uppLeft {
	display: inline-block;
	margin-right: 160px;
}
.createJob .uppRight {
	display: inline-block;
}

/* Manage job */

.manageJob {
	margin-top: 3%;
}
.MJHeader h3 {
	color: #3c948c;
	display: inline-block;
}

.MJHeader button {
	float: right;
}
.manageJobActionButton .btn-secondary{
	background-color: #a32a2a;
	border: none;
	margin: 0 3px;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 5px;
	color: white;
	float: inline-start;
}
.manageJobActionButton .btn-primary{
	border: none;
	margin: 0 8px;
	color: white;
	font-size: 14px;
	padding: 2px 2px;
	border-radius: 5px;
	float: inline-start;
	background-color: green;
}
.manageJobPagination {
	margin-top: 40px;
}
/*---------------accdetail--------------*/
.MyProfileTopSection {
	width: 100%;
	display: flex;
	margin-top: 40px;
	overflow: hidden;
	padding: 24px 16px;
	align-items: center;
	border-radius: 10px;
	border: 1px solid #EBEBEB;
	justify-content: space-between;
	background:url("/public/Images/mainBanner/diamonds.jpeg") center;
	background-size: cover;
}
.MYProfileHeader {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

}
.MyProfileImg {
	width: 140px;
	height: 140px;
	overflow: hidden;
	margin-right: 25px;
	position: relative;
	border-radius: 16px;
	display: inline-block;
	vertical-align: middle;
	background: transparent;
	box-shadow: 0 0 5px 0 #9e9e9c;
}
.MyProfileDetails h2 {
	font-size: 20px;
	color: #333333;
	font-weight: normal;
	text-transform: capitalize;
}
.MyProfileDetails h6 {
	display: block;
	font-size: 15px;
	color: #333333;
	font-weight: 400;
	font-style: normal;
}
.MyProfileUpgratePlan {
	gap: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.MyProfileUpgratePlan span {
	font-size: 16px;
	color: #333333;
	font-weight: 400;
	display: inline-block;
}
.UploadBackBg{ position: absolute; right: 0; bottom: 0; border-radius: 0;background-color: #294a9c; border: none; padding: 8px 25px}
.EditJobseekerProfileTag {
	gap: 20px;
	display: flex;
}
.EditJobseekerProfileTag a {
	font-size: 16px;
	color: #294A9C;
}
.EditJobseekerProfileTag button {
	border: none;
	background: none;
}
.JobseekerProfileActive {
	display: flex;
	align-items: center;
}
.CreatedDate {
	font-size: 16px;
	display: inline-block;
}
.Calcultn {
	padding: 12px;
	min-width: 200px;
	border-radius: 4px;
	background-color: #DDE6F8;
}
.Calcultn h4 {
	margin-bottom: 8px;
}
.Calcultn h6 {
	color: #333333;
	font-weight: normal;
}
.Calcultn span {
	color: #818181;
	font-weight: normal;
}
.LeftSideCalu {
	float: left;
	margin-top: 8px;
}
.LeftSideCalu i {
	color: #b3b3b3;
	float: left;
	margin-right: 5px;
	font-size: 26px;
}
.RightSideCalu {
	float: right;
	padding-left: 5px;
}
.RightSideCalu h4 {
	font-size: 22px;
	font-style: normal;
	color: #7d7d7d;
}
.RightSideCalu h6 {
	font-size: 12px;
	font-style: normal;
	color: #ababab;
}
.JobseekersTabsBx {
	display: inline-block;
	width: 100%;
	margin-top: 30px;
}
.JobseekersTabs .nav-link {
	color: #333;
	font-size: 18px;
	font-weight: bold;
	padding: 16px 24px;
	position: relative;
	background: transparent;
	border: 1px #ddd solid;
	border-bottom: none;
	border-radius: 15px 15px 0 0;
}
.JobseekersTabs .nav-link.active {
	color: #294a9c;
	font-weight: bold;
}
.JobseekersTabs {
	border-bottom: 1px #ddd solid;
}
.JobseekersTabsContent {
	padding: 20px 0;
}
.JobseekersContentTop{
	width: 100%;
	padding: 20px;
	border-radius: 4px;
	display: inline-block;
	border: 1px solid #ddd;
}
.JobseekersContentTop ul {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
}
.JobseekersContentTop ul li {
	min-width: 100px;
	text-align: center;
}
.JobseekersContentTop ul li h4{
	font-size: 24px;
	color: #333333;
	margin-bottom: 8px;
}
.JobseekersContentTop ul li h6 {
	font-size: 16px;
	color: #4F4F4F;
	font-weight: normal;
}
.JobseekersContentSearch {
	float: right;
	width: 28%;
    margin-top: 30px;
    position: relative
}
.JobseekersContentSearch .form-control {
	height: 46px;
	border-radius: 0;
	padding-right: 35px;
}
.JobseekersContentSearch span {
	position: absolute;
	right: 11px;
	top: 10px;
	opacity: 0.5;
}
.JobseekersSearchContent{
	width: 100%;
	display: inline-block;
}
.JobseekersSearchContent a{
	color: #294A9C;
}
.no_found {
	font-size: 17px;
	padding: 20px 0;
	text-align: center;
}
.JobseekersSearchContent .tableBox {
	overflow-x: auto;
	border-radius: 4px;
	white-space: nowrap;
	border: 1px solid #ddd;
}
.JobseekersSearchContent .tableBox::-webkit-scrollbar {
	height: 4px;
}
.JobseekersSearchContent .tableBox .leftSide {
	gap: 24px;
	display: flex;
	min-width: 250px;
	align-items: center;
}
.JobseekersSearchContent .tableBox .rightSide {
	gap: 24px;
	display: flex;
	align-items: center;
}
.JobseekersSearchContent .tableHeader {
	gap: 12px;
	display: flex;
	font-weight: bold;
	padding: 16px 24px;
	align-items: center;
	text-align: start;
	justify-content: space-between;
	border-bottom: 1px solid #ddd;
}
.JobseekersSearchContent .tableHeader .rightSide .appliedDate {
	width: 100%;
	min-width: 110px;
}
.JobseekersSearchContent .tableHeader .rightSide .rating {
	width: 100%;
	min-width: 100px;
}
.JobseekersSearchContent .tableHeader .rightSide .statusSelect {
	width: 100%;
	min-width: 160px;
}
.JobseekersSearchContent .tableHeader .rightSide .jobApplyType {
	width: 100%;
	min-width: 160px;
}
.JobseekersSearchContent .tableBody .tableItem {
	gap: 12px;
	display: flex;
	padding: 16px 24px;
	align-items: center;
	text-align: justify;
	justify-content: space-between;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .appliedDate {
	width: 100%;
	min-width: 110px;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .rating {
	width: 100%;
	min-width: 100px;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .statusSelect {
	width: 100%;
	min-width: 160px;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .jobApplyType {
	width: 100%;
	min-width: 160px;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .jobApplyType a {
	display: block;
	color: #294A9C;
	font-weight: 500;
}
.JobseekersSearchContent .tableBody .tableItem .rightSide .jobApplyType p {
	font-size: 14px;
}
.jobApplyType {
	min-width: 160px;
}
.jobApplyType a {
	display: block;
	color: #294A9C;
	font-weight: 500;
}
.jobApplyType p {
	font-size: 14px;
}
.JobseekersSearchContent input {
	width: 20px;
	height: 20px;
}
.JobseekersSearchContent button {
	margin-right: auto;
}
.JobseekersTabsDetails {
	display: inline-block;
	width: 100%;
	background: #f7f7f7;
	padding: 20px;
}
.JobseekersTabsDetailsList {
	display: inline-block;
	width: 100%;
	margin-bottom: 13px;
}
.JobseekersTabsDetailsList label {
	width: 30%;
	color: #4a4948;
	font-size: 15px;margin-top: 6px;
	vertical-align: top;
	font-weight: 600;
}
.JobseekersTabsDetailsList span {
    width: 68%;
    color: #4a4948;
    font-size: 15px;
    display: inline-block;

}
.JobseekersTabsDetailsList span em {
	font-size: 15px;
	color: #797979;
	font-style: normal;
	line-height: 35px;
}
.selectFormInnerAccDetails {
    opacity: 1;
    height: 45px;
    outline: none;
    font-size: 16px;
    color: #797979;
    min-width: 150px;
	padding: 6px 36px 6px 12px;
	box-shadow: none !important;
    min-width: -moz-fit-content;
    border: 1px dashed #a7a6a6;
}
.usernameInnerAccDetails p {
	font-weight: bold;
}
.EPEJimageViewer {
    margin-top: 20px;
}
.manageJobInternalpageImage {
	width: 200px;
	height: 200px;
	padding: 15px;
	border: 1px solid white;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 5px;
}
.manageJobInternalpageImage:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sendMailHeader {
	background-color: #294a9c;
	color: white;
	font-weight: 500;
    padding: 15px;
    text-align: left;
	margin-bottom: 10px;
}
.sendMailInputManageJobs{
	margin-top: 30px;
	padding: 0 10px;
}
.sendMailInputManageJobs input{
	height: 60px;
	border: 1px dashed #a7a6a6;
}
.sendMailManageJobsButtons{
	margin: 15px 0px;
	text-align: end;
	padding: 0 10px;
}
.sendMailManageJobsButtons button{
	margin-left: 10px;
}
/* Payment History */

.paymentHistory {
	margin-top: 3%;
}

.paymentHistory h3 {
	color: #3c8cc4;
}

.paymentHistory .paymentButton {
	color: white;
	font-size: 16px;
	font-weight: 500;
	padding: 9px 25px;
	border-radius: 3px;
	background-color: #294a9c;
	border: 2px #294a9c solid;
	min-width: -webkit-fill-available;
}
.paymentHistory .paymentButton.deactivated {
	background-color: #FF0B0F;
	border: 2px #FF0B0F solid;
}
.paymentHistoryPagination {
	margin-top: 20px;
}
/* Favourite List */
.favouriteList {
	margin-top: 3%;
}
.favouriteList h3 {
	color: #dc445c;
}
.createFolderInput {
	width: 260px;
}
.favouriteListFoldersBox {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
}
.favouriteListFoldersBox a {
	color: #212529;
}
.favouriteListFolder {
	width: 220px;
	padding: 16px;
	border-radius: 4px;
	background: #DEE6F6;
}
.favouriteListFolderName {
	overflow: hidden;
	margin-right: 8px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.favouriteListFoldersButton {
	padding: 0;
	border: none;
	background: none;
	color: #FF0B0F;
	margin-top: 16px;
}
.favouriteListFoldersButton:disabled {
	opacity: 0.7;
}
.favouriteListFoldersButton:not(:disabled):hover {
	text-decoration: underline;
}
.foldersList {
	width: 310px;
	overflow-y: auto;
	max-height: 200px;
	margin-bottom: 24px;
}
.foldersListItemButton {
	border: none;
	height: 40px;
	width: 300px;
	display: block;
	background: none;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.foldersListItemButton.active {
	border: none;
	border-radius: 4px;
	background-color: #E2E2E2;
}
.foldersListItemChekbox {
	border: none;
	width: 300px;
	display: block;
	cursor: pointer;
	background: none;
	overflow: hidden;
	text-align: left;
	margin-bottom: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.foldersListItemChekbox input {
	cursor: pointer;
	accent-color: #FF0B0F;
}

/* Favourite List Profile */

.FavouriteListProfile {
	margin-top: 3%;
}

.FLPHeaderCard {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border: 1px solid var(--border, #e0e0e0);
	min-height: 210px;
}
.FLPTextarea {
	padding: 20px;
}
/* .emailValueFLP{
	padding-top: 5px;
}
.contactValueFLP {
	padding-top: 5px;
} */

.viewLink{
	padding-left: 8px;
}
.FLPLeftSideHeader .ImageSpace {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background-color: #cfcccc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.FLPImage {
	border-radius: 50%;
    height: 210px;
    width: 220px;
    padding: 0px;
}
.FLPRightSideHeader {
	padding: 20px 0 20px 23px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.FLPRightSideHeader h4 {
	display: inline-block;
	color: #f3734c;
	padding-top: 6px;
}
.FavouriteListProfile h4 {
	color: #f3734c;
}

.FLPlink{
	color: #f3734c;
	font-weight:500;
}

.FLPRightSideBody {
	margin: 0 0 30px 25px;
}
.FLPRightSideBody h6 {
	margin-top: 7px;
}
.listProfileParent {
	margin: 4px 0;
}
.listProfileChild {
	margin-top: 8px;
}
.FLPEducationTable {
	border: 1px solid var(--border, #e0e0e0);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    overflow-y: auto;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.FLPNAText {
	margin: 15px 0 25px 0;
}
.FLPAboutCandidateHeader {
	margin-top: 20px;
}
.FLPAboutCandidateBody {
	margin: 8px 0;
}
.FLPCandidateCertificateHeader {
	margin-top: 20px;
}
.FLPCandidateCertificateBody {
	margin: 15px 0;
}
.FLPCandidateCertificateBody {
	margin: 15px 0;
}
.FLPCandidateCertificateBody .imageBox{
	padding: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 5px;
	width: fit-content;
	min-width: 120px;
	margin-left: 15px;
	margin-top: 12px;
	cursor: pointer;
}
.FLPCandidateCertificateImage {
	border: 1px solid #dddddd;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin: 15px 10px;
	max-width: 270px;
}
.documentBox {
	width: fit-content;
    margin: 17px 0 0 12px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	cursor: pointer;
	padding: 13px;
}

.favListPagination {
	margin-top: 20px;
}
/* Import Jobseeker */
.importJobseeker {
	margin-top: 3%;
}

.importJobseeker h3 {
	color: #9474c4;
}

.importJobseeker input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
	padding: 22px 20px;
}
.importJobseeker input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}


/* Mail History */

.mailHistory{
	margin-top: 3%;
}

.mailHistory h3 {
	color: #4864ac
}

.mailHistoryPagination {
	margin-top: 20px;
}
/* Mail detail */

.mailDetail {
	line-height: 40px;
}
.mailDetailBx {
	background-color: rgb(240, 240, 238);
	padding: 15px;
	border-radius: 10px;

}
.mailDetail h5{
	line-height: 40px;
	margin-bottom: 20px;
}

/* My profile */

.myProfile {
	margin-top: 3%;
}

.myProfile h3 {
	color: #a46cc4;
}

.myProfile .form-outline {
	margin: 0 20px 0 20px;
}

.myProfile input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6; padding: 0 20px; box-shadow: none;
}
.myProfile input:hover {
	border: 1px dashed #a46cc4;
	box-shadow: #a46cc4 10px 14px 22px;
}
.myProfile select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.myProfile select:hover {
	border: 1px dashed #a46cc4;
	box-shadow: #a46cc4 10px 14px 22px;
}


/* new code for my profile */

.myProfileEmployerBody {
	margin-top: 20px;
}
.myProfileEmployerBody .card-title h4 {
	font-size: 21px;
}

.myProfileEmployerBody .cardOne {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardOne:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardOne .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardOne .card-title h4{
	display: inline-block;

}
.myProfileEmployerBody .cardOne .card-title p{
	float: right;
	font-size: 15px;
	color: #555454;
}

.myProfileEmployerBody .cardOne .profileImage{
	width: 100%;
	max-width: 180px;
}

.myProfileEmployerBody .nameSection {
	width: 100%;
	margin: 15px 0;
}

.myProfileEmployerBody .nameSection p{
	display: inline-block;
	font-weight: 500;
	padding-top: 5px;
	font-size: 17px;
}
.myProfileEmployerBody .nameSection a{
	display: block;
	font-size: 17px;
	font-weight: 400;
	padding-top: 5px;
	max-width: fit-content;
}


.myProfileEmployerBody .cardOneEditButton {
	padding: 5px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	float: right;
	font-size: 15px;
	color: black;
}

.myProfileEmployerBody .cardTwo {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardTwo:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardTwo .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardTwo .card-title h4{
	display: inline-block;
}
.myProfileEmployerBody .cardTwoPrimaryButton{
	margin: 20px 0;
	padding: 5px 25px;
	background-color: f2f2f4;
	color: rgb(63 105 223);
	border-radius: 100px;
	border: none;
	font-size: 12px;
}
.myProfileEmployerBody .cardTwoChangeEmailButton{
	margin-top: 20px;
	padding: 5px 25px;
	/* background-color: rgb(176, 176, 177); */
	color: rgb(63 105 223);
	border-radius: 10px;
	border: 1px solid grey;
}
.myProfileEmployerBody .cardTwoChangeButton {
	padding: 8px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	font-size: 15px;
	float: right;
	color: black;
}

.myProfileEmployerBody .cardThree {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardThree:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardThree .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardThree .card-title h4{
	display: inline-block;
}
.myProfileEmployerBody .cardThreeChangeButton {
	border: none;
	color: black;
	font-size: 17px;
	padding: 8px 18px;
	border-radius: 100px;
	background-color: rgb(233 219 244);
}


.myProfileEmployerBody .cardFour {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardFour:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardFour .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardFour .card-title h4{
	display: inline-block;
}
.myProfileEmployerBody .cardFour .card-title p{
	float: right;
	font-size: 15px;
}
.myProfileEmployerBody .cardFive {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardFive:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardFive .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardFive .card-title h4{
	display: inline-block;
}
.myProfileEmployerBody .cardFive .card-title p{
	float: right;
	font-size: 15px;
}
.myProfileEmployerBody .cardSix {
	padding: 20px;
	margin-bottom: 15px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.myProfileEmployerBody .cardSix:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.myProfileEmployerBody .cardSix .card-title {
	width: 100%;
}
.myProfileEmployerBody .cardSix .card-title h4{
	display: inline-block;
}
.myProfileEmployerBody .cardSix .card-title p{
	float: right;
	font-size: 15px;
}
.myProfileEmployerBody .cardSix .planNameBox ul{
	width: 100%;
	margin: 15px 0;
}
.myProfileEmployerBody .cardSix .planNameBox .list1{
	display: inline-block;
	padding-top: 8px;
}
.myProfileEmployerBody .cardSix .planNameBox .list2{
	float: right;
}
.myProfileEmployerBody .cardSix .planNameBox .changePlanButton{
	padding: 8px 18px;
	background-color: rgb(233 219 244);
	border: none;
	border-radius: 100px;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
	font-size: 15px;
	color: black;
}
.myProfileEmployerBody .cardSix .planDetailBox{
	margin-top: 15px;
}
.myProfileEmployerBody .cardSix .featuresDescription{
	margin: 20px 0 0 0;
}

.noPlanAvl{
	width: 100%;
	margin: 15px 0;
}
.noPlanAvl p{
	display: inline-block;
	padding-top: 5px;
}
.noPlanAvlLink {
	float: right;
}
.certificatesSection {
	margin: 0 0 20px 0;
}
.certificatesSection p{
	padding-right: 30px;
}
.certificatesSection li {
	list-style: none;
}
.eachDocumentMyprofile {
	display: flex;
	cursor: pointer;
}
.eachDocumentMyprofile i{
	padding-top: 3px;
}
.eachCertificateMyprofile i {
	min-height: 120px;
}
.planDetailsJobseeker {
	margin-top: 15px;
}
.planDetailsJobseeker .parent {
	display: flex;
	margin-bottom: 6px;
}
.planDetailsJobseeker .parent .child1{
	min-width: 39%;
}


/* Edit profile */

.editProfile {
	margin-top: 3%;
}

.editProfile h3 {
	color: #4cb4b4;
}

.editProfile input:not(.MuiInputBase-input) {
	color: black;
	border: none;
	outline: none;
	font-size: 17px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}
.editProfileForm .checkboxInput {
	width: 20px;
	height: 20px;
}
.editProfile .skillPackage input {
	height: 58px;
}
.editProfile select {
	border: none;
	outline: none;
	font-size: 17px;
	font-weight: 400;
	color: black;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.editProfile select:hover {
	border: 1px dashed #4cb4b4;
	box-shadow: #4cb4b4 10px 14px 22px;
}
.toolbar {
	gap: 6px;
	display: flex;
	margin-top: 6px;
	margin-bottom: 6px;
}
.toolbar button {
	border: none;
	border-radius: 4px;
}

/* Membership Plan */
.membershipPlan {
	margin-top: 3%;
}
.membershipPlan h3 {
	color: #3c8cc4;
}

/* Change Password */

.changePassword {
	margin-top: 3%;
}

.changePassword h3 {
	color: #54acec;
}

.changePassword .form-outline {
	margin: 0 16px;
}

.changePassword input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
	padding: 5px 20px;
}
.changePassword input:hover {
	border: 1px dashed #54acec;
	box-shadow: #54acec 10px 14px 22px;
}

/* Alerts */

.JSEditAlert .PageHeader h3 {
	color: #54acec;
}

/* Change logo */

.changeLogo {
	margin-top: 3%;
}

.changeLogo h3 {
	color: #dc8474;
}

.changeLogo .form-outline {
	margin: 0 20px 0 20px;
}

.changeLogo input {

	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:fit-content;
	border: 1px dashed #a7a6a6;
}
.changeLogo input:hover {
	border: 1px dashed #dc8474;
	box-shadow: #dc8474 10px 14px 22px;
}
/* .changeLogoButtons {
	margin-left: 23px;
} */
.CLPanelRight {
	padding: 0 30px;
}
.ChangePhotoEmployers {
	border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 170px;
    height: 170px;
    padding: 10px;
    border-radius: 5px;
}
.ChangePhotoEmployers img {
	width: 150px;
    height: 150px;
    border-radius: 5px;
}
.profileImageBox {
	width: 200px;
	display: flex;
    height: 200px;
    padding: 10px;
    border-radius: 5px;
	border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.profileImageBox img {
	margin: auto;
	max-height: 100%;
    border-radius: 5px;
}
/*----------------New-Design-------*/
.SidebarCreatJobBg{background-image: url("/public/Images/employerSide/bg1.png");}
.SidebarManageJobBg{background-image: url("/public/Images/employerSide/bg2.png");}
.SidebarPaymentJobBg{background-image: url("/public/Images/employerSide/bg3.png");}
.SidebarDocumentsBg{background-image: url("/public/Images/employerSide/bg12.png");}
.SidebarFavouriteJobBg{background-image: url("/public/Images/employerSide/bg4.png");}
.SidebarImportJobBg{background-image: url("/public/Images/employerSide/bg5.png");}
.SidebarMailHistoryBg{background-image: url("/public/Images/employerSide/bg6.png");}
.SidebarMyProfileBg{background-image: url("/public/Images/employerSide/bg7.png");}
.SidebarEditProfileBg{background-image: url("/public/Images/employerSide/bg8.png");}
.SidebarChangePassBg{background-image: url("/public/Images/employerSide/bg9.png");}
.SidebarChangeLogoBg{background-image: url("/public/Images/employerSide/bg10.png");}
.SidebarLogOutBg{background-image: url("/public/Images/employerSide/bg11.png");}
.SidebarDeleteAccBg{background-image: url("/public/Images/employerSide/bg12.png");}
.SidebarImages img {filter: brightness(0%);opacity: 0.7;}

.SidebarCreatJob:hover{ color: #f3734c;}
.SidebarManageJob:hover{ color: #4d9b93;}
.SidebarPaymentJob:hover{ color: #4682b0;}
.SidebarMyProfile:not(.disabled):hover{ color: #a268bf;}
.SidebarFavouriteJob:not(.disabled):hover{ color: #dc425e;}
.SidebarChangePass:not(.disabled):hover{ color: #51a8eb;}
.SidebarMailHistory:not(.disabled):hover{ color: #4663ae;}
.SidebarImportJob:hover{ color: #6e5595;}
.SidebarEditProfile:hover{ color: #50afb4;}
.SidebarChangeLogo:hover{ color: #dd8678;}
.SidebarLogOut:hover{ color: #a268bf;}
.SidebarDeleteAcc:hover{ color: #d44e84;}

.bodyItem.Active {font-weight: 500;border-radius: 5px; }
.bodyItem.Active .SidebarImages{background-image: none;}
.bodyItem.Active .SidebarImages {background-image: none;background-color: #fff;border-radius: 5px; position: relative;}
.bodyItem.Active .SidebarImages img {filter: brightness(100%);opacity: 1;position: absolute;left: 0;top: 0;bottom: 0;right: 0;margin: auto;}

.SidebarMyProfile.Active {background: #f7ebfd; color: #a268bf; }
.SidebarMyProfile.disabled {cursor: default; opacity: 0.7;}
.bodyItem.Active .SidebarMyProfileBg {border: 1px #a268bf dashed;}

.SidebarFavouriteJob.Active {background: #f9ebee; color: #dc425e; }
.SidebarFavouriteJob.disabled {cursor: default; opacity: 0.7;}
.bodyItem.Active .SidebarFavouriteJobBg {border: 1px #dc425e dashed;}

.SidebarChangePass.Active {background: #dceefc; color: #51a8eb; }
.SidebarChangePass.disabled {cursor: default; opacity: 0.7;}
.bodyItem.Active .SidebarChangePassBg {border: 1px #51a8eb dashed;}

.SidebarMailHistory.Active {background: #dce4f9; color: #4663ae; }
.SidebarMailHistory.disabled {cursor: default; opacity: 0.7;}
.bodyItem.Active .SidebarMailHistoryBg {border: 1px #4663ae dashed;}

.SidebarCreatJob.Active {background: #fcf1ed; color: #f3734c; }
.bodyItem.Active .SidebarCreatJobBg {border: 1px #f3734c dashed;}

.SidebarManageJob.Active {background: #dcf4f2; color: #4d9b93; }
.bodyItem.Active .SidebarManageJobBg {border: 1px #4d9b93 dashed;}

.SidebarPaymentJob.Active {background: #e4edf5; color: #4682b0; }
.bodyItem.Active .SidebarPaymentJobBg {border: 1px #4682b0 dashed;}

.SidebarImportJob.Active {background: #d4c1f1; color: #916ace; }
.bodyItem.Active .SidebarImportJob {border: 1px #916ace dashed;}

.SidebarEditProfile.Active {background: #d4f6f8; color: #50afb4; }
.bodyItem.Active .SidebarEditProfileBg {border: 1px #50afb4 dashed;}

.SidebarChangeLogo.Active {background: #fde5e1; color: #dd8678; }
.bodyItem.Active .SidebarChangeLogoBg {border: 1px #dd8678 dashed;}

.SidebarDeleteAcc.Active {background: #f5adca; color: #d44e84; }
.bodyItem.Active .SidebarDeleteAccBg {border: 1px #d44e84 dashed;}

.myProfile .DashBoardInputBx input:not(.MuiInputBase-input):hover {border: 1px dashed #a26fbd;box-shadow: 0px 0px 0px 0 rgba(162,111,189,0.5);}

.DashBoardInputBx {position: relative;}
.DashBoardInputBx .form-label {font-size: 16px; position: absolute;left: 12px;top: -13px;z-index: 9;background: #fff;padding: 0 8px; color: rgba(0, 0, 0, 0.6);}
.DashBoardInputBx input:not(.MuiInputBase-input):hover {border: 1px dashed #a26fbd;box-shadow: 4px 14px 28px 0 rgba(162,111,189,0.5);}
.DashBoardCreatBx input:hover {border: 1px dashed #f3734c;box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);}
.TopHaddingTitle h3 i {display: inline-block;vertical-align: top;}
.TopHaddingTitle h3 span {vertical-align: top;display: inline-block;padding: 5px 0 0 5px;}
.TopHaddingTitle .button1 {font-size: 14px;}

.MJBody .table tr{-webkit-transition: .3s ease;-o-transition: .3s ease;transition: .3s ease;}
.MJBody .table tr.TrDefoult:hover {border: 0px solid #c2d2d1;box-shadow: 4px 14px 28px 0 rgba(102,166,159,0.3);}
.TrFirst th {padding: 16px 20px;font-weight: normal;color: rgba(0,0,0,0.8);font-size: 16px;}
.TrDefoult td{padding: 25px 16px;font-weight: normal;color: rgba(70,70,70,0.8);font-size: 16px;}
.ActiveButton input[switch] {display: none;}
.ActiveButton input[switch] + label {font-size: 1em;line-height: 1;width: 100px;height: 30px;background-color: #ddd;background-image: none;
	border-radius: 2rem;padding: 5px;cursor: pointer;display: inline-block;text-align: center;position: relative;transition: all 0.1s ease-in-out;}
.ActiveButton input[switch] + label::before {
	color: #b7b7b7;
	content: attr(data-off-label);
	display: block;
	font-weight: 400;
	font-size: 16px;
	position: absolute;
	right: 8px;
	margin: 0;
	top: 6px;
	text-align: center;
	min-width: 1.66666667rem;
	overflow: hidden;
	transition: all 0.1s ease-in-out;
}
  .ActiveButton input[switch] + label::after {
	content: '';
	position: absolute;
	left: 6px;
	background-color: #f7f7f7;
	box-shadow: none;
	border-radius: 2rem;
	height: 22px;
	width: 22px;
	transition: all 0.1s ease-in-out;
	top: 4px;
}
  .ActiveButton input[switch]:checked + label {
	background-color: #f3734c;
  }
  .ActiveButton input[switch]:checked + label:before {
	color: #fff;
	content: attr(data-on-label);
	right: auto;
	left: 11px;
  }
  .ActiveButton input[switch]:checked + label:after {
	left: 72px;
	background-color: #f7f7f7;
  }
  .ActiveButton input[switch="bool"] + label {
	background-color: #dde3f1;
  }
  .ActiveButton input[switch="bool"] + label:before {
	color: rgba(36,37,42,0.8) !important;;
  }
  .ActiveButton input[switch="bool"]:checked + label {
	background-color: #ee6562;
  }
  .ActiveButton input[switch="bool"]:checked + label:before {
	color: #fff !important;
  }
  .ActiveButton input[switch="default"]:checked + label {
	background-color: #a2a2a2;
  }
  .ActiveButton input[switch="default"]:checked + label:before {
	color: #fff !important;
  }
  .ActiveButton input[switch="success"]:checked + label {
	background-color: #BCE954;
  }
  .ActiveButton input[switch="success"]:checked + label:before {
	color: #fff !important;
  }
  .ActiveButton input[switch="warning"]:checked + label {
	background-color: gold;
  }
  .ActiveButton input[switch="warning"]:checked + label:before {
	color: #fff !important;
  }

  .PHBody .table tr.TrDefoult:hover {
	border: 0px solid #d1e0ec;
	box-shadow: 4px 14px 28px 0 rgba(97,145,185,0.3);
}
.TrActions .btn {
	margin: 0 3px;
	padding: 2px 6px;
	font-size: 14px;
	border-radius: 5px;
}
.TrActions .btn-secondary{background-color: #f3734c; border: none;}

.FLBody .table tr.TrDefoult:hover {
	border: 0px solid #fdd1d8;
	box-shadow: 4px 14px 28px 0 rgba(224,94,115,0.3);
}
.MHBody .table tr.TrDefoult:hover {
	border: 0px solid #c2d0f4;
	box-shadow: 4px 14px 28px 0 rgba(70,99,174,0.3);
}
.editProfile input:not(.MuiInputBase-input):hover {
	border: 1px dashed #4cb4b4;
	box-shadow: 4px 14px 28px 0 rgba(76, 180, 180, 0.3);
}

.changeLogo .form-control[type="file"] ,
.editProfile .form-control[type="file"] {
	padding: 20px;
}
.RedStar {
	color: #f00;
}
.changePassword input:hover {
	border: 1px dashed #54acec;
	box-shadow: 4px 14px 28px 0 rgba(84,172,236,0.3);
}
.changeLogo input:hover {
	border: 1px dashed #dc8474;
	box-shadow: 4px 14px 28px 0 rgba(220,132,116,0.3);
}

/* .form-outline {
	margin: 0 0 0 20px;
} */
.ChoosPlanBx {
	display: inline-block;
	width: 100%;
	border: 1px dashed #d0cece;
	padding: 17px 20px;
	height: 70px;
	border-radius: 5px;transition: all .3s ease-in-out;
}
.ChoosPlanBx .button1 {
	background: #294a9c;
	border: none;
	font-size: 14px;
	margin-left: 20px;
	padding: 7px 15px;
	font-weight: 400;
}
.ChoosPlanBx:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 4px 14px 28px 0 rgba(162,111,189,0.5);
}
.AvailablePlanBx {
	display: inline-block;
	width: 100%;
	border: 1px dashed #d0cece;
	padding: 17px 20px;
	height: auto;
	border-radius: 5px;transition: all .3s ease-in-out;
}
.AvailablePlanBx:hover {
	border: 1px dashed #a26fbd;
	box-shadow: 4px 14px 28px 0 rgba(162,111,189,0.5);
}
.availablePlanFeaturesKey {
	font-weight: 500;
}
.defaultnavbar .navbar-toggler {
	box-shadow: none;
}
.ContactDetails {
	width: 28%;
	margin: 0 1%;
	display: flex;
	padding: 22px;
	align-items: center;
	border-radius: 10px;
	border: 1px #ddd solid;
	min-height: 130px; text-align: left;
}
.ContactDetails i {
	display: inline-block;
	margin: 0;
	vertical-align: top;
	width: 64px;
	font-size: 20px;opacity: 0.7;
}
.ContactDetailsBx {
	display: inline-block;
	width: 75%;
	vertical-align: top;
	padding-left: 15px;
}
.ContactDetailsBx strong{ font-weight: 500; display: block;font-size: 19px;}
.ContactDetailsBx span {
	font-size: 17px;
	color: rgba(0,0,0,0.7);
}
.contactInfo .card {
	border: none;
	border-radius: 0;
}
.ContactTopHadding {
	margin-bottom: 23px;
}
.ContactTopHadding .card-title {
	font-size: 30px;
	font-weight: 500;
}
.ContactTopHadding .card-body {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.7);
}
.MapIfream {
	background: #f1f1f1;
	padding: 5px;
	border: 5px #ddd solid;
	border-radius: 20px;
	height: 100%;
}
.ContactForm {
	background: #edeff4;
	padding: 40px;
	border-radius: 29px;
	box-shadow: none;
	border: none;
	display: inline-block;
	width: 100%;
}
.ContactForm .form-select {
	height: 48px;
	padding: 6px 12px;
	border: 1px solid #dadada;
}
.ContactSection3 {
	margin-bottom: 50px;
}
.MapIfream iframe {
	max-height: 100%;
	height: 100%;
}
.contactInfo {
	text-align: center;
}
.ContactForm .input-group textarea.form-control{ height: 150px; border: 1px solid rgba(202,202,202,0.6); border-radius: 6px !important;
font-size: 16px;resize: none;}
.contactInfo .card .card-body {
	padding: 0 0 20px;
}
.aboutusSection1 h1 {
	font-size: 40px;
	color: #25242a;
	font-weight: 500;
	max-width: 1012px;
}
.aboutusSection1 h6 {
	margin-top: 10px;
	font-weight: 300;
	font-size: 18px;
	color: rgba(37,36,42,0.9) !important;
}
.AboutImgFirst {
	display: inline-block;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 6px;
}
.aboutusSection3 p {
	color: rgba(37,36,42,0.8);
	font-size: 18px;
}
.AboutUsUsers {
	border-bottom: 1px #e5e5e5 solid;
	padding-bottom: 20px;
}
.aboutusSection2content p {
	color: rgba(82,82,82,0.8);
	font-size: 18px;
	padding-bottom: 15px;
	max-width: 80%;
	margin: 0 auto;
}
.aboutusSection4 .card h3 {
	font-weight: normal;
	font-size: 28px;
	color: #201f1f;
	padding-bottom: 10px;
}
.aboutusSection4 .card h3 span {
	font-weight: 500;
}
.aboutusSection4 .card p {
	font-weight: normal;
	font-size: 18px;
	color: #505050;
	text-align: start !important;
}
.aboutusSection4 {
	padding-bottom: 60px;
}
.searchItems {
    width: 20%;
    position: relative;
}
.faqSection1 h1,
.faqSection2 h3 {
	font-size: 40px;
	color: #25242a;
	margin-bottom: 10px;
}
.upperPart h4 {
	color: #303030;
	font-size: 20px;
	font-weight: 500;
}
.upperPart p {
	color: #303030;
	font-size: 20px;
	font-weight: 300;
	padding-top: 5px;
}
.lowerPart {
	margin-top: 40px;
}
.lowerPart .accordion-item {
	border-radius: 0;
	border: none;
	border-bottom: 1px #ddd solid;
}
.lowerPart .accordion{border-top: 1px #ddd solid;}
.lowerPart .accordion-item .accordion-header .accordion-button {display: block;padding:28px 10px 28px 70px; font-size: 24px ;
	background: transparent;color: #303030 ;border-radius: 0; box-shadow: none;  }

.lowerPart .panel-body {padding: 0px 15px 15px 70px;border: none !important;font-size: 18px;}
.lowerPart .panel-default {border: none;border-bottom: 1px #ddd solid;border-radius: 0;}
.lowerPart .panel-default > .panel-heading {color: #333;background-color: #fff;border: none;border-radius: 0; padding: 0;}

.lowerPart .accordion-item .accordion-header .accordion-button::after {
	background-image: url("/public/Images/plus-icon.png"); position: absolute; left: 25px; top: 34px; width: 15px; height: 15px;background-repeat: no-repeat;
	background-size: 100%;
	box-shadow: none;
}
.lowerPart .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
	background-image: url("/public/Images/min-icon.png"); position: absolute; left: 25px; top: 34px; width: 15px; height: 15px;background-repeat: no-repeat;
	background-size: 100%;
	box-shadow: none;transform: initial;
}
.lowerPart .accordion-item .accordion-body {
	color: #303030;
	font-size: 20px;
	font-weight: 300;
	padding-top: 5px;
}
@media(max-width: 991px){
	.navbardefault {
		position: absolute;
		left: 0;
		top: 100%;
		background: #fff;
		width: 100%;
		z-index: 999;
	}
	.navbardefault .navigation {
		display: inline-block;
		width: 100%;
		overflow: visible;
		height: fit-content;
		background-color: white;
		/* max-height: initial; */
		padding-bottom: 7px;border-top: 1px #ddd solid;
	}
	.navbardefault .navitems {
		margin: 0;
		display: block;
		padding: 15px;
		border-bottom: 1px #ddd solid;
	}
	.navbardefault .navitems:hover::before, .navbardefault .navitems.active::before {
		display: none;
	}
	.navbardefault .btn-primary {
		margin-top: 20px;
	}
	.SearchIcon {
		padding: 11px 0;
		display: inline-block;
		vertical-align: top;
		margin: 0 3px;
	}
	.defaultnavbar .dropdown {
		border-top: 1px #ddd solid;
		background-color: white;
		padding: 8px 16px;
		margin: 0 !important;
	}
	.defaultnavbar .navButton1 {
		margin-left: 3px;
		background-color: #294A9C;
		border-color: #294A9C;
	}
	.defaultnavbar .navButton2 {
		margin-left: 3px;
		min-width: 135px;
		background-color: white;
	}
	.MJBody {
		overflow-x: auto;
	}
	.FooterLinks {
		margin-bottom: 30px;
	}
	.footerFooter {
		margin-top: 10px;
	}
	.CategoryBx {
		margin: 20px 0;
		text-align: center;
	}
	.CategoryBx p {
		max-width: 100%;
	}
	.CategoryBx img {
		margin-bottom: 0;
		width: 80px;
	}
}

/* JOBSEEKER SIDE */

/*------------JobseekerProfile--------------*/
.JobseekerProfileBx {
	width: 100%;
	margin-top: 20px;
	display: inline-block;
}
.JobseekerProfileTopBx {
	gap: 20px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 10px;
	padding: 10px 16px 16px;
	justify-content: space-between;
	border-bottom: 1px solid rgb(211, 209, 209, 0.25);
}
.JobseekerProfileTopBx h3 {
	font-size: 24px;
	color: #333333;
	display: inline-block;
}
@media screen and (max-width: 767px) {
	.JobseekerProfileTopBx h3 {
		font-size: 20px;
	}
}
.EditProfileTag {
	float: right;
}
.EditProfileTag a {
	color: rgba(79, 81, 82, 0.8);
	font-size: 18px;
}
.JobseekerProfileTopBx h3 span {
	vertical-align: middle;
}
.JobseekerProfileTopBx h3 i {
	margin-right: 7px;
}
.JobseekerProfileDetails {
	width: 100%;
	padding: 16px;
	display: inline-block;
}
.JobseekerProfileDetails .JobseekerProfileDetailsBox {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	justify-content: space-between;
}
.JobseekerProfileDetails .JobseekerProfileSearch {
	gap: 24px;
	display: flex;
}
.JobseekerProfileBxDetailTop {
	display: inline-block;
	width: 100%;
}
.JobseekerProfileImg {
	display: inline-block;
	width: 110px;
	height: 110px;
	overflow: hidden;
	border-radius: 50%;
	vertical-align: middle;
}
.JobseekerProfileBxDetailTop h3 {
	display: inline-block;
	color: #333;
	font-size: 30px;
	padding-left: 30px;
}
.ProfileDetails {
	display: inline-block;
	width: 100%;
	margin-top: 20px;
}
.ProfileDetails ul {
	display: inline-block;
	width: 100%;
}
.ProfileDetails ul li {
	display: inline-block;
	padding-right: 20px;
	color: rgba(36,37,42,0.8);
	font-size: 16px;
	margin-bottom: 10px;
}
.ProfileDetails ul li.full-width {
	width: 100%;
}
.ProfileDetails ul li i, .ProfileDetails ul li span {
	vertical-align: middle;
}
.ProfileDetails ul li i{
	margin-right: 5px;
	color: #838285;
}
.JobseekerEducationDetails{
	padding: 20px;
	display: inline-block;
	width: 100%;
}
.JobseekerEducationDetails ul{
	display: inline-block;
	width: 100%;
}
.JobseekerEducationDetails ul li{
	display: inline-block;
	width: 100%;
	color: rgba(36,37,42,0.8);
	font-size: 16px;
	position: relative;
	padding-left: 20px;
	margin-bottom: 5px;
}
.JobseekerEducationDetails ul li::before{
	position: absolute;
	left: 0;
	top: 8px;
	content: "";
	background: #9d9c9f;
	width: 8px;
	height: 8px;
	border-radius: 50%;
}
.JobseekerSkillsAdd{
	display: inline-block;
	width: 100%;
	padding: 20px;
}
.AddProfileTag{
	float: right;
}
.AddProfileTag a {
	color: rgba(79, 81, 82, 0.8);
	font-size: 18px;
}
.AddProfileTag a i{
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}
.AddProfileTag a span{
	display: inline-block;
	vertical-align: middle;
}
.SkillsAddBxMain {
	display: inline-block;
	width: 100%;
}
.SkillsAddBxMain ul {
	display: inline-block;
	width: 100%;
}
.SkillsAddBxMain ul li {
	display: inline-block;
	background: #e6e6e6;
	padding: 5px 20px;
	border-radius: 20px;
	position: relative;
	font-size: 16px;
	color: rgba(0,0,0,0.7);
	margin-right: 10px;
	margin-bottom: 10px;
}
.RemoveSkills {
	float: right;
	background: #535353;
	width: 22px;
	text-align: center;
	height: 22px;
	border-radius: 50%;
	margin-left: 10px;
	margin-top: 1px;
}
.JobseekerEducationDetails p{
	font-size: 16px;
	color: rgba(0,0,0,0.7);
}
.CurrentPlanBx {
	display: inline-block;
	width: 100%;
}
.CurrentPlanBx label {
	display: inline-block;
	width: 100%;
	font-size: 16px;
	color: rgba(0,0,0,0.7);
	margin-bottom: 15px;
}
.CurrentPlanBx .btn-primary{
	background: #294a9c;
	border: none;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 2px;
	padding: 7px 20px;
}
.JobseekerCertificatesDetails{
	display: inline-block;
	width: 100%;
	padding: 20px;
}
.JobseekerCertificatesDetails ul{
	display: flex;
	width: 100%;
}
.JobseekerCertificatesDetails ul li {
    display: inline-block;
    background: #e6e6e6;
    padding: 10px;
    border-radius: 9px;
    position: relative;
    font-size: 16px;
    color: rgba(0,0,0,0.7);
    margin-right: 10px;
    width: 20%;
	min-height: 40px;
}
.JobseekerCertificatesDetails ul li i{
	display: inline-block;
	vertical-align:top;
	margin-right: 5px;
}
.JobseekerCertificatesDetails ul li span{
	display: inline-block;
	vertical-align: middle;
}


.jsprofileCross {
	position: absolute;
	right: 0;
	cursor: pointer;
	top: 0;
}
.myProfileLinks {
	width: 100%;
	display: inline-block;
	margin-top: 30px;
}
.myProfileEachButton {
	margin: 40px 20px 0 0;
}

.JobseekerDocsDetails{
	display: flex;
	width: 100%;
	padding: 20px;
}

.JobseekerDocsDetails ul{
	display: inline-block;
	width: 100%;
	cursor: pointer;
}
.JobseekerDocsDetails ul li {
    display: inline-block;
    background: #e6e6e6;
    padding: 10px;
    border-radius: 9px;
    position: relative;
    font-size: 16px;
    color: rgba(0,0,0,0.7);
    margin-right: 10px;
    /* width: 20%; */
	min-height: 40px;
}
.JobseekerDocsDetails ul li i{
	display: inline-block;
	vertical-align:top;
	margin-right: 5px;
}
.JobseekerDocsDetails ul li span{
	display: inline-block;
	vertical-align: middle;
}


/* JSEditprofile */
.radioInput {
	padding: 20px;
	font-size: 17px;
    border-radius: 8px;
	border: 1px dashed #a7a6a6;
}
.editProfileForm .radioInput input {
	height: auto;
    margin-right: 5px;
}
.editProfileForm .stepButtons {
	gap: 8px;
	display: flex;
	justify-content: space-between;
}
.editProfileForm .stepButtons button {
	display: flex;
	align-items: center;
}
.formTabs {
	gap: 1px;
	display: flex;
	justify-content: center;
}
.formTabs .button2 {
	padding: 10px;
	border-radius: 10px 10px 0px 0px;
}
.formTabs .button2.active {
	color: #FFFFFF;
    border-color: #FF0B0F;
    background-color: #FF0B0F;
}
.radioInput .labelMale {
	padding-right: 20px;
}
.searchWrapper{
	border: 1px dashed #ccc;
    border-radius: 40px;
    min-height: 22px;
    padding: 0px;
    position: relative;
}
.coverDelete {
	width: 100%;
	padding-bottom: 70px;
	padding-right: 20px;

}
.coverDelete button {
	float: right;
}
.preferredLocationBx {
	z-index: 9999;
}

.EPJobseekerCertificatesDetails ul li {
    display: inline-block;
    background: #e6e6e6;
    padding: 10px;
    border-radius: 9px;
    position: relative;
    font-size: 16px;
    color: rgba(0,0,0,0.7);
    margin-right: 10px;
    width: auto;
	min-height: 40px;
	max-height: 48px;
}
.EPJobseekerCertificatesDetails{
	display: flex;
	width: 100%; ;
}
.EPJobseekerCertificatesDetails ul{
	display: inline-block;
	width: 100%;
}
.checkCertificate {
    height: auto;
}
.checkCertificate .EPJobseekerCertificatesDetails ul li {
    width: 20%;
    max-height: initial;
}
.checkCertificate .EPJobseekerVidio ul li {
    width: 100%;
    max-height: initial;
}
@media screen and (max-width: 767px) {
	.editProfileFormMobile {
		border-top: 2px solid #294A9C;
	}
	.formTabsMobileBox {
		border-bottom: 2px solid #294A9C;
	}
	.formTabsMobile {
		width: 100%;
		border: none;
		display: flex;
		color: #294A9C;
		font-size: 20px;
		padding: 16px 0;
		background: none;
		font-weight: 500;
		align-items: center;
		justify-content: space-between;
	}
	.formTabsMobile:disabled {
		opacity: 0.7;
	}
	.formTabsMobile i {
		font-size: 24px;
	}
	.editProfileForm .stepButtons button {
		padding: 10px;
	}
}

/* Education */
.EduAddMore {
	text-align: right;
	margin-right: 20px;
}

/* Edit Experience */

.noData {
	padding: 30px 10px;
	border: none;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin-bottom: 15px;
}

.removeButtonJS {
	text-align: right;
}
.jobseekerAddMore {
	text-align: right;
	margin-right: 22px;
}
.bottomButtonsEducation {
	margin-left: 27px;
}

/* Professional Registratiopn */
.PRAddMore {
	text-align: right;
}

/* JSMyCV */
.cheaps {
	gap: 24px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

/* cvDescription */
.cvDescription {
	margin-top: 24px;
	line-height: 140%;
	padding-top: 24px;
	border-top: 2px solid #F4F3F3;
}
.cvDescription h1 {
	color: #333333;
	font-size: 36px;
	font-weight: 500;
	max-width: 1072px;
	margin-bottom: 28px;
}
.cvDescription h2 {
	color: #333333;
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 12px;
}
.cvDescription h3 {
	color: #333333;
	font-size: 20px;
	font-weight: 500;
}
.cvDescription h4 {
	color: #727475;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 24px;
}
.cvDescription h5 {
	color: #333333;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 20px;
}
.cvDescription h6 {
	color: #FF0B0F;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
}
.cvDescription i {
	width: 24px;
	color: #838285;
}
.personalInfoSection {
	gap: 24px;
	display: flex;
	padding-bottom: 24px;
	justify-content: space-between;
	border-bottom: 2px solid #F4F3F3;
}
.personalInfoBox {
	margin-bottom: 32px;
}
.genderAgeRegionBox {
	gap: 12px;
	display: flex;
	margin-bottom: 12px;
}
.contactsBox {
	gap: 8px;
	display: flex;
	margin-bottom: 32px;
	flex-direction: column;
}
.contactsBox p {
	font-size: 16px;
	font-weight: 500;
}
.contactsBox a {
	color: #294A9C;
}
.contactsBox a:hover {
	text-decoration: underline !important;
}
.socialLinksBox {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 32px;
}
.socialLinksBox button {
	padding: 0;
	line-height: 1;
	font-size: 28px;
}
.socialLinksBox .socialLinks {
	gap: 12px;
	display: flex;
}
.socialLinksBox .socialLinks i {
	width: auto;
	color: #294A9C;
}
.socialLinksBox .verticalStrip {
	width: 2px;
	height: 30px;
	border: 1px solid #294A9C;
}
.socialLinksBox .messengersLinks {
	gap: 12px;
	display: flex;
}
.socialLinksBox .messengersLinks i {
	width: auto;
	color: #294A9C;
}
.wishesInfoBox {
	gap: 8px;
	display: flex;
	flex-direction: column;
}
.wishesInfoBox span {
	font-weight: 500;
}
.experienceInfoSection {
	padding-top: 24px;
	padding-bottom: 24px;
	border-bottom: 2px solid #F4F3F3;
}
.experienceInfoBox {
	gap: 24px;
	display: flex;
	flex-direction: column;
}
.industryBox {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 16px;
}
.workDurationBox {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 24px;
}
.workDuration {
	color: #838285;
	font-weight: 500;
}
.achievements {
	font-weight: 500;
	margin-bottom: 16px;
}
.skillsSection {
	padding-top: 24px;
	padding-bottom: 24px;
	border-bottom: 2px solid #F4F3F3;
}
.skillsBox {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
}
.skillsBox button {
	color: #6E6E85;
	cursor: default;
	font-size: 16px;
	background: none;
	font-weight: 500;
	padding: 6px 16px;
	border-radius: 20px;
	border: 1px solid #78788D;
}
.educationSection {
	padding-top: 24px;
	padding-bottom: 24px;
	border-bottom: 2px solid #F4F3F3;
}
.educationBox {
	gap: 24px;
	display: flex;
	margin-bottom: 24px;
	flex-direction: column;
}
.additionalEducationBox {
	gap: 24px;
	display: flex;
	flex-direction: column;
}
.additionalEducationBox a {
	margin-bottom: 20px;
	display: inline-block;
}
.additionalEducationBox p {
	margin-bottom: 16px;
}
.languagesSection {
	padding-top: 24px;
	padding-bottom: 24px;
	border-bottom: 2px solid #F4F3F3;
}
.languagesBox {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
}
.languagesBox span {
	font-weight: 500;
}.aboutYourselfSection {
	padding-top: 24px;
	padding-bottom: 24px;
	border-bottom: 2px solid #F4F3F3;
}
.driverLicenseSection {
	padding-top: 24px;
	padding-bottom: 24px;
}
.driverLicenseBox {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
.driverLicenseBox span {
	font-weight: 500;
}
@media screen and (max-width: 767px) {
	.cvDescription {
		font-size: 14px;
	}
	.cvDescription h1 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.cvDescription h2 {
		font-size: 20px;
	}
	.cvDescription h3 {
		font-size: 16px;
	}
	.cvDescription h4 {
		margin-bottom: 20px;
	}
	.cvDescription h5 {
		margin-bottom: 16px;
	}
	.personalInfoSection {
		flex-direction: column-reverse;
	}
	.personalInfoBox {
		margin-bottom: 20px;
	}
	.contactsBox {
		gap: 12px;
		margin-bottom: 20px;
	}
	.socialLinksBox {
		gap: 20px;
		margin-bottom: 20px;
	}
	.socialLinksBox .messengersLinks {
		gap: 20px;
	}
	.socialLinksBox .socialLinks {
		gap: 20px;
	}
	.industryBox {
		gap: 8px;
	}
	.workDurationBox {
		gap: 12px;
		margin-bottom: 20px;
	}
	.skillsBox {
		gap: 12px;
	}
	.skillsBox button {
		font-size: 14px;
	}
	.educationBox {
		gap: 20px;
	}
	.educationBox .workDuration {
		margin-bottom: 12px;
	}
}

/* JSMake CV */
.makeCV {
	margin-top: 3%;
}
.makeCV h3 {
	color: red
}
.makeCVSeg1 {
	border: 1px dashed rgb(204, 204, 204);
	border-radius: 10px;
	margin-top: 20px;
}
.makeCVAddress {
	margin-top: 8px;
	padding: 0px 0px 20px 0;
	font-weight: bold;
}
.makeCVUserDetail {
	padding: 0px 0px 20px 20px;
	width: 100%;
	margin-top: 20px;
}
.makeCVUserDetail .makeCVEmail {
	display: inline-block;
	margin-right: 50px;
	margin-top: 20px;
}
.makeCVUserDetail .makeCVPhone {
	display: block;
	margin-top: 8px;
}
.makeaCVBottomBotton {
    margin-top: 30px;
    float: right;
}
.makeaCVBottomBotton button{
    font-size: 18px;
}

/* VideoCV */

.videoDisplay {
	margin: 20px 0 40px 0;
}

/* Inner membership */
.MembershipCardAuth {
    padding: 30px 5px;
}
.membershipCardAuthUpperPart {
	min-height: 190px;
}
.membershipCardMain {
	height: 100%;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.membershipCardMain:hover{
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.MembershipCardAuth h4 {
	height: 90px;
	color: #25242a;
	font-size: 25px;
	font-weight: 500;
}
.MembershipCardAuth h2 {
	margin: 20px 0 0;
	font-size: 50px;
	font-weight: 600;
	color: rgba(41,74,156,0.9);
}
.MembershipCardAuth h6 {
	color: #25242a;
	font-size: 17px;
	opacity: 0.8;
	font-weight: normal;
}
.ApplyAllAuth {
	border-top: 2px solid rgba(222,222,222,0.5);
	margin-top: 20px;
	padding: 23px 20px 0;
	text-align: left;
	margin-right: 35px;
}
.ApplyAllAuth .form-check-input[type="checkbox"] {
	border-radius: 2px;
	border-width: 2px;
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.ApplyAllAuth .form-check-label {
	color: #25242a;
	font-size: 15px;
	opacity: 0.7;
	padding-top: 2px;
}
.applyoptionAuth {
	text-align: left;
	padding: 23px 0 0 16px;
}
.applyoptionAuth li {
	margin: 9px 0;
	font-size: 14px;
	list-style: none;
	position: relative;
	padding: 0 0 0 28px;
	color: rgba(36,37,42,0.7);
	background: url("/public/Images/BlueArrow.png") no-repeat left center;
}
.MembershipCardAuth .btn-primary {
	background: #294a9c;
	border: none;
	border-radius: 3px;
	font-size: 19px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 7px 32px;
	width: 84%;
	margin-top: 30px;
}
.MembershipCardAuth .btn-secondary {
    background: #f3734c;
    border: none;
    border-radius: 3px;
    font-size: 19px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding: 7px 32px;
    width: 84%;
    margin-top: 30px;
}
.paymentOptionsAvailable {
	display: flex;
    justify-content: space-around;
}

/* JSSavedJobs */

.JSSavedJob {
	margin-top: 3%;
}

.JSSavedJob h3 {
	color: #dc445c;
}

.JSsavedJobsPagination {
	margin-top: 50px;
}
/* JSPayment history */
.PHBody {
    overflow-x: auto;
}
.TrActions {
    min-width: 108px;
}
.JSPHEachCol {
	min-width: 140px;
}
.JSPHFirstCol {
	min-width: 30px;
}
.JSPHSecondCol {
	min-width: 190px;
}

.JSPaymentHistoryPagination {
	margin-top: 50px;
}
/* JSManageAlerts */

.JSMASection2 {
	gap: 20px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}
.EditAlertButtons {
	margin-left: 23px;
}
.JSmanageAlertPagination {
	margin-top: 50px;
}

/* JSApplied Jobs */
.JSAppliedJobsPagination {
	margin-top: 50px;
}
/* JSMail Detail */
.MDButtonBx {
	width: 100%;
	display: inline-block;
}
.JSMailHistoryPagination {
	margin-top: 50px;
}
/* JSChange Photo */
.JSChangePhoto {
	width: 200px;
    height: 200px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #fff;
    box-shadow: 0 3px 8px rgba(0,0,0,.24);

}
.jobseekerChangePhoto img {
    max-height: 100%;
    border-radius: 5px;
}

/* Job Details */
.single-job-detail {
	display: inline-block;
	width: 100%;

}
.logo-detail {
    float: left;
    width: 150px;
    height: 150px;
    background: #f1f1f1;
    border-radius: 5px;
    overflow: hidden;
    /* padding-top: 15px; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	position: relative;
}
.logo-detail:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.logo-detail .JDImage {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.job-meta-detail {
	float: left;
	padding-left: 30px;
	padding-top: 10px;
	width: 73%;
}
.job-meta-detail h2 {
	color: #25242a;
	font-weight: 400;
	font-size: 23px;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 10px;
}
.job-meta-detail p {
	margin: 0;
	padding: 0;
	margin-right: 6px;
	color: #666666;
	font-size: 14px;
	line-height: 26px;
}
.job-meta-detail p i {
	margin-right: 3px;
	color: #C0BEBE;
	font-size: 14px;
	width: 18px;
}
.JobDetailTopBx {
	background: #f1f1f1;
	margin-bottom: 30px;
	padding: 10px 0px 40px 0px;
}
.JobDetailTopBx h6 {
	margin: 10px 0px 20px 0px;
}
.JobDetailBtnBx {
	text-align: right;
	margin-top: 50px;
}
.ShareBtnDetails {
	display: inline-block;
}
.ShareBtnDetails a img {
	opacity: 0.5;
}
.shareIconsList {
	width: 100%;
}
.shareIconsButtons {
	list-style: none;
	margin-top: 10px;
	margin-right: 8px;
	display: inline-block;
}
.ApplyJobBtnBx {
	gap: 20px;
	display: flex;
	flex-direction: column;
}
@media screen and (min-width: 768px) {
	.ApplyJobBtnBx {
		flex-direction: row;
		justify-content: end;
	}
}
.JobDescriptionBx {
	padding-top: 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-style: normal;
	color: #333;
}
.JobDescriptionBx h2 {
	font-size: 24px;
	color: #25242a;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
}
.JobDescriptionBx p {
	padding-top: 5px;
}
.similarJobsSection {
	padding-top: 30px;
}
.similarJobsSection h2{
	font-weight: 500;
}
.JobDetailsCard h2 {
	font-size: 22px;
}
.JobDetailsInfo {
	padding-top: 10px;
}
.JobDetailsInfo ul {
	width: 100%;
	display: inline-block;
}
.JobDetailsInfo ul li {
	color: #666;
	width: 100%;
	font-size: 16px;
	padding-top: 10px;
	display: inline-block;
}
.JobDetailsInfo ul li i {
	width: 25px;
	color: #979797;
}
.hideContact {
	text-decoration: underline;
}
.JobDetailsCard {
	background: #fff;
	border-color: #ddd;
	border-radius: 10px;
}
.JobDetailsCard:hover {
	box-shadow:none;
	border: 1px solid #ddd;
	background: #fff;
}
.JobDetailsDescriptionSection {
	padding-bottom: 40px;
}
.jobDescriptionKeys {
	font-weight: 600;
}
.JcardFooterItem1 {
	width: 20%;
	margin-top: 5px;
}
.catCard .catCardTitle {
	font-size: 22px;
	min-height: 50px;
}
.jobDescriptionModal .resumeHead {
	display: flex;
	font-weight: bold;
	margin-bottom: 12px;
	justify-content: space-between;
}
.jobDescriptionModal .resumeBox {
	gap: 8px;
	display: flex;
	margin-top: 12px;
	flex-direction: column;
	justify-content: space-between;
}
.jobDescriptionModal .resumeItem {
	padding: 6px;
	display: flex;
	cursor: pointer;
	border-radius: 3px;
	align-items: center;
	border: 1px solid #BBBBBB;
	justify-content: space-between;
}
.jobDescriptionModal .resumeItem:hover {
	border-radius: 3px;
	background-color: #E2E9FC;
	border: 1px solid #294A9C;
}
.jobDescriptionModal .resumeItem.selected {
	border-radius: 3px;
	background-color: #E2E9FC;
	border: 1px solid #294A9C;
}
.jobDescriptionModal .resumeLabelBox {
	overflow: hidden;
	margin-right: 6px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jobDescriptionModal .resumeLabel {
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 400;
	text-align: start;
}
.jobDescriptionModal .resumeRadio {
	display: none;
	font-size: 20px;
	cursor: pointer;
	margin-right: 8px;
	accent-color: #294A9C;
}
.jobDescriptionModal .coverLetterLabel {
	width: 100%;
	font-weight: bold;
	margin-bottom: 8px;
	text-align: left !important;
}
.jobApplyModal h2 {
	font-size: 24px;
	line-height: 125%;
}
.jobApplyModal p {
	font-size: 14px;
}
.jobApplyModal a {
	color: #294A9C;
	font-size: 14px;
}
.enterInfoBox {
	display: flex;
	max-width: 540px;
	padding: 40px 60px;
	border-radius: 10px;
	flex-direction: column;
	border: 1px solid #D8D8D8;
}
@media screen and (max-width: 767px) {
	.enterInfoBox {
		padding: 20px;
	}

}
.questionnaireBox {
	gap: 12px;
	display: flex;
	flex-direction: column;
}
.questionnaireBox a {
	font-size: 16px;
	color: #294A9C;
	font-weight: 600;
}
.applyJobSelectForm .selectBox {
	padding: 12px;
	border-radius: 3px;
	margin-bottom: 12px;
	border: 1px solid #BBBBBB;
}
.applyJobSelectForm .selectBoxHeader {
	width: 100%;
	border: none;
	display: flex;
	background: none;
	font-weight: 600;
	align-items: center;
	justify-content: space-between;
}
.applyJobSelectForm .coverLetterBox {
	margin-bottom: 24px;
}
.applyJobSelectForm .fileUploadBox {
	gap: 8px;
	display: flex;
	height: 180px;
	padding: 12px;
	border-radius: 3px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border: 1px dashed #BBBBBB;
}
.applyJobSelectForm .fileUploadBox .fileUploadItem {
	gap: 8px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.applyJobSelectForm .fileUploadBox .fileUploadLabel {
	color: #294A9C;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-left: 6px;
}
.applyJobSelectForm .fileUploadBox .fileUploadInput {
	display: none;
}
.subscribeAlertBox {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}
.subscribeAlertLabel {
	display: flex;
	align-items: center;
}
.subscribeAlertCheckbox {
	width: 20px;
	height: 20px;
	margin-right: 8px;
	accent-color: #294A9C;
}
.dataProcessingConsentBox {
	max-width: 340px;
	margin-bottom: 24px;
}
.jobApplyResultBox {
	max-width: 990px;
	padding: 44px 60px;
	border-radius: 10px;
	margin: 60px auto 40px;
}
.jobApplyResultBox.success {
	background-color: #EAFFF1;
	border: 1px solid #166F32;
}
.jobApplyResultBox.warning {
	background-color: #FFF7D1;
	border: 1px solid #B38E00;
}
.jobApplyResultBox.error {
	background-color: #FFE8E8;
	border: 1px solid #8B1A1A;
}
@media screen and (max-width: 767px) {
	.jobApplyResultBox {
		padding: 44px 30px;
	}
}

@media screen and (min-width: 768px) {
	.TopEmployersBxCardBx:nth-child(4) .EmployersLogoBx{ border-right: none;}
	.TopEmployersBxCardBx:nth-child(5) .EmployersLogoBx{ border-bottom: none;}
	.TopEmployersBxCardBx:nth-child(6) .EmployersLogoBx{  border-bottom: none;}
	.TopEmployersBxCardBx:nth-child(7) .EmployersLogoBx{  border-bottom: none;}
	.TopEmployersBxCardBx:nth-child(8) .EmployersLogoBx{  border-bottom: none; border-right: none;}
}
@media screen and (max-width: 767px) {
	.TopEmployersBxCardBx:nth-last-child() .EmployersLogoBx{ border-bottom: none;}
}



/********************************** ADMIN PANEL **************************************/

/* Admin Panel Navbar */
/* .optionContent {

}
.bodyContent {

} */

.APNavbar {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #fff;
}
.APNavbar li {
    list-style: none;
}

/* .APNavbar {
	width: 100%;
} */
.nav-left img {
	width: 28px;
	height: 18px;
	flex-shrink: 0;
	margin-top: 5px;
}
.APNavSectionLeft {
	margin-left: 25px;
}
.APNavSectionRight {
	width: 100%;
}
.APNavSectionRight .part1 {
	display: inline-block;
	margin-right: 25px;
}
.APNavSectionRight .part1 a{
	margin-left: 25px;
}
.APNavSectionRight .part2 {
	display: inline-block;
}
/* .APNavSectionRight .part2 {
	width: 100%;
} */
.APNavSectionRight .part2 .APNavInner1 {
	display: inline-block;
	margin-right: 15px;
}
.APNavSectionRight .part2 .APNavInner2 {
	display: inline-block;
	padding-top: 5px;
}
.APNavSectionRight .part2 .APNavInner3 {
	display: inline-block;
}

.nav-right {
	display: inline-flex;
	align-items: center;
	gap: 32px;
}
.nav-right img {
	width: 24px;
	height: 24px;
}

.nav-right .part1 {
	display: flex;
	align-items: flex-start;
	gap: 24px;
}

.nav-right .bellIndicator .bell {
	margin-top: 8px;;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
}
.nav-right .bellIndicator .indicator {
	margin-bottom: 5px;
	width: 4px;
	height: 4px;
	flex-shrink: 0;
}
.nav-right .part2 {
	display: flex;
	align-items: center;
	gap: 12px;
}

.nav-right .part2 p {
	color: var(--primary, #000);

/* Body */
	/* font-family: 'Poppins'; */
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 24px */
	margin-top: 10px;
}
.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu {
    right: 10px;
    width: 283px;
    left: auto;
    margin-top: 15px;
    padding: 20px;
    text-align: center;
}

.APNavbar .custom-dropdown-menu a{
	text-decoration: none;
	color: black;
}
.APNavbar .custom-dropdown-menu .dropdownMenu :hover{
	text-decoration: none;
	color: white;
	color: #f3734c;
}
.dropdownMenuLower {
	margin-top: 20px;
	background-color: #f3734c;
	padding: 10px 0;
	border-radius: 7px;
}
.dropdownMenuLower:hover {
	margin-top: 20px;
	background-color: #294a9c;
	padding: 10px 0;
	border-radius: 7px;
	color: white;
}


/* AP Basic */
.APBasic {
	overflow-x: hidden;
	width: 100%;
}
/* .APBasic h2 {
	color: #f3734c;
} */

/* .APBasic a {
    color: #000;
    cursor: pointer;
    text-decoration: none;
} */
/* .APBasic li {
    list-style: none;
} */
.SidebarList > a {
    position: relative;
    display: inline-block;
    width: 100%;
}

.APButton3 {
	color: #294a9c;
	font-size: 14px;
	font-weight: 500;
	min-width: 150px;
	padding: 9px 25px;
	margin-left: 10px;
	border-radius: 5px;
	background-color: white;
	border: 2px #294a9c solid;
}

.APButton3:hover {
	color: #FF0B0F;
	border-color:#FF0B0F;
	background-color: #fff;
}
.APButton4 {
	color: #FF0B0F;
	font-size: 14px;
	font-weight: 500;
	min-width: 150px;
	margin-left: 10px;
	padding: 9px 25px;
	border-radius: 5px;
	background-color: white;
	border: 2px #FF0B0F solid;
}

.APButton4:hover {
	color: #294a9c;
	border-color:#294a9c;
	background-color: #fff;
}

.adminValidation {
	text-align: justify;
}

.APBasic input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}
.APBasic input:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.APBasic textarea {
	border: none;
	outline: none;
	font-size: 17px;
	min-height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}
.APBasic textarea:hover {
	border: 1px dashed #a46cc4;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.APBasic select {
	border: none;
	outline: none;
	font-size: 17px;
	height: 70px;
	font-weight: 400;
	opacity: 0.8;
	min-width:100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
	color: black;
}
.APBasic select:hover {
	border: 1px dashed #f3734c;
	box-shadow: 4px 14px 28px 0 rgba(242,107,73,0.3);
}
.APRadioInput {
	border: 1px dashed #a7a6a6;
    border-radius: 8px;
    padding: 21px 0px;
	max-width: 1100px;
}
.APRadioInput input {
	height: 15px;
}
.breadCumb {
	margin: 10px 0 20px 0;
}
.checkBoxCol {
	line-height: initial;
}
.checkBoxCol .tableCheckBox {
    height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
}
.table tbody tr:last-child {
    border-color: #fff;
}
.checkBoxDiv input {
	height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
}
.breadCumb1 {
    margin: 0 0 12px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 20px 0 20px 5px;

}
.APJoditEditor {
	max-width: 1100px;
}
.APJoditEditor ul {
	padding-left: 32px;
}
.APJoditEditor ol {
	padding-left: 32px;
}
.selectedInputImage {
	width: auto;
    padding: 14px;
    height: 215px;
    border: 1px solid #e1dfdf;
    border-radius: 10px;
    margin: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.selectedImageArea {
	width: 250px;
    height: 250px;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 20px;
}
.selectedImageButton {
	margin-top: 20px;
}

.APBasic .error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}
.APBasic .input-error {
    padding: 20px;
    font-size: 17px;
    font-weight: 400;
    min-width: 100px;
    max-width: 1100px;
    border: 1px dashed #ff0000;
}
.adminMediumButton1{
    color: white;
    background-color: #294a9c;
    font-weight: 500;
    border: 2px #294a9c solid;
    font-size: 16px;
    padding: 9px 25px;
    border-radius: 3px;
    margin-left: 15px;
    min-width: 135px;
}
.adminMediumButton1:hover{
    color: white;
    background-color: #f3734c;
    font-weight: 500;
    border: 2px #f3734c solid;
    font-size: 16px;
    padding: 9px 25px;
    border-radius: 3px;
    margin-left: 15px;
    min-width: 135px;
}
.adminLowerButton1{
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
}
.adminLowerButton1:hover{
	color: white;
	background-color: #f3734c;
	font-weight: 500;
	border: 2px #f3734c solid;
	font-size: 17px;
	padding: 9px 25px;
	border-radius: 3px;
}
.adminLowerButton2{
	color: #f3734c;
    background-color: white;
    font-weight: 500;
    border: 2px #f3734c solid;
    font-size: 17px;
    padding: 9px 25px;
    border-radius: 3px;
    margin-left: 10px;
}
.adminLowerButton2:hover{
	color: #294a9c;
    background-color: white;
    font-weight: 500;
    border: 2px #294a9c solid;
    font-size: 17px;
    padding: 9px 25px;
    border-radius: 3px;
    margin-left: 10px;
}


/* Styling the page scroller */
/* Webkit (Safari and Chrome) */
::-webkit-scrollbar {
	width: 6px; /* Adjust the width of the scrollbar as needed */
  }

  ::-webkit-scrollbar-thumb {
	background-color: #C35656; /* Change to the color you want */
	border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  }

  /* Style the horizontal scrollbar */
::-webkit-scrollbar-horizontal {
    height: 6px; /* Adjust the height of the scrollbar as needed */
}

/* Style the horizontal scrollbar thumb */
::-webkit-scrollbar-thumb:horizontal {
    background-color: #C35656; /* Change to the color you want */
    border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

/* Location suggestions for admin panel */

.suggestionsAdminSide {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2px;
    padding: 10px;
    position: absolute;
    top: 69px;
    z-index: 99;
    min-width: 100px;
    max-width: 1100px;
    width: -webkit-fill-available;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.suggestionsAdminSide  li {
	padding: 5px;
	cursor: pointer;
}
.suggestionsAdminSide  li:hover {
	background-color: #ef9478;
	border-radius: 8px;
}





/* Admin Panel Login */

.centerContainer {
	min-height: 100vh;

}
.loginCardStyles {
	width: 500px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: none;
    margin: 150px  auto 0;
}
.belowSectionAdminLogin {
	text-align: center;
	margin-top: 30px;
}
.centerContainer input {
	border: none;
	outline: none;
	font-size: 17px;
	height: 60px;
	font-weight: 400;
	opacity: 0.8;
	min-width:100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 20px 20px;
}
.passwordVisibilityAdmin {
	position: absolute;
    right: 13px;
    top: 16px;
}


/* Sidebar */
/* .Sidebar {
	height: 100%;
	width: auto;
	background-color: #2F4050;
} */
/* .SidebarList {
	height: auto;
	padding: 0;
	width: 100%;

} */

.site-min-height {
    margin: 90px 0 30px 290px;
}

.Sidebar {
    background-color: #2f4050;
    width: 270px;
    height: 100%;
    position: fixed;
    padding-bottom: 90px;
}

.SidebarList {
    height: 100%;
    padding: 0;
    width: 100%;
    margin-top: 83px;
    overflow-y: auto;
}
.SidebarList .row {
    width: auto;
    height: 47px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
	position: relative;
    /*font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.SidebarList .row:hover {
	cursor: pointer;
	background-color: #293846;
}

.SidebarList #active {
	background-color: 293846;
}
.SidebarList > a:first-child::before {
    position: absolute;
    left: 10px;
    bottom: 0;
    content: "";
    width: 92%;
    height: 2px;
    background: #171b1e;
    z-index: 999;
}
.row #icon {
	flex: 20%;
	display: grid;
	place-items: center;
	padding-left: 10px;
}
.row #icon2 {
    position: absolute;
    right: 0;
    width: auto;
    top: 11px;
}
.row #title {
    flex: 75% 1;
    padding-top: 1px;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
.SidebarListInternal {
	background-color: black;
}
.SidebarListInternal .row {
    width: 100%;
    height: 43px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.Sidebar .dropdown {
    border: 1px solid #171b1e;
    border-radius: 0;
    /* padding: 0px 10px; */
    margin: 0px 10px;
}
.SidebarListInternal {
    background-color: #253039;
}
.SidebarListInternal .row #innerIcon {
    flex: 20% 1;
    display: grid;
    place-items: center;
    padding-left: 9px;
    padding-right: 0;
}
.SidebarListInternal .row #innerTitle {
    flex: 74% 1;
    padding: 3px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}
.SidebarListInternal .row:hover {
	cursor: pointer;
	background-color: #293846;
	border-radius: 20px;
}
.SidebarListInternal .row .css-i4bv87-MuiSvgIcon-root {
    width: 15px;
    height: 15px;
}

/* .APSidebar {
	width: 250px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	overflow-y: auto;
} */
.SidebarList .dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out; /* Smooth transition */
}

.SidebarList .dropdown.open {
  max-height: 1000px; /* Adjust as needed */
  transition: max-height 0.9s ease-in-out; /* Smooth transition */
}

.adminSidebarLinks h3 {
	color: #fff;
	font-size: 26px;
	font-weight: 500;
}
/* .adminSidebarLinks ul {
	margin-top: 20px;
} */
.adminSidebarLinks ul li {
	margin-top: 20px;
}
.adminSidebarLinks ul li a {
	font-size: 17px; color: #fff; font-weight: 300;
}
.sidebarEachHeader {
	width: 100%;
}
.sidebarEachHeader h3 {
	display: inline-block;
	padding-top: 20px;
}
.adminSidebarPlusLink {
	color: #fff;
	float: right;
	font-size: 22px;
	padding-top: 20px;
	margin-bottom: 15px;
	margin-right: 15px;
}
.sidebarEachHeader a{
	color: white;
}


/* Styling Sidebar Scroller */

.SidebarList {
	/* Your existing styles for SidebarList here */

	/* Change the color of the scrollbar thumb (the draggable part) */
	scrollbar-color: #f3734c; /* Change 'red' and 'green' to your desired colors */
  }

  /* Style the scrollbar track (the background) */
  .SidebarList::-webkit-scrollbar {
	width: 6px; /* Adjust the width as needed */
  }

  /* Style the scrollbar thumb (the draggable part) */
  .SidebarList::-webkit-scrollbar-thumb {
	background-color: #f3734c; /* Change 'red' to your desired color */
  }

  /* Style the scrollbar track (the background) on hover */
  /* .SidebarList::-webkit-scrollbar-thumb:hover {
	background-color: blue;
  } */

.adminPageHeading {
	margin-top: 30px;
}


/* Dashboard */

.dashboardHeader p{
	color: rgb(148, 148, 148);
	margin-top: 10px;
}
.dashboardSection1 {
	width: 100%;
	margin-top: 30px;
}
.dashboardSection1 .cardUpperLeft a {
	text-decoration: none;
	cursor: pointer;
	color: black;
}
.dashboardCard1 {
	display: inline-block;
	border: 1px solid #148EF4;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}
.dashboardCard2 {
	display: inline-block;
	border: 1px solid #ED8936;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}
.dashboardCard3 {
	display: inline-block;
	border: 1px solid #38B2AC;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}
.dashboardCard4 {
	display: inline-block;
	border: 1px solid #F3734C;
	width: 100%;
	height: 130px;
	border-radius: 12px;
	background: #FFF;
	/* margin-right: 20px; */
	padding: 15px;
}

.dashboardCard2,
.dashboardCard3,
.dashboardCard4,
.dashboardCard1{
	position: relative;
}
.dashboardBody .cardLower {
    width: 60px;
    position: absolute;
    height: 60px;
    text-align: center;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
    border-radius: 10px;
    line-height: 54px;
}
.dashboardCard1 .cardLower {
    background: #148EF4
}
.dashboardCard2 .cardLower {
    background: #ED8936
}
.dashboardCard3 .cardLower {
    background: #38B2AC
}
.dashboardCard4 .cardLower {
    background: #F3734C
}

.dashboardBody .cardUpperLeft {
	display: inline-block;
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}
.dashboardBody .cardUpperRight {
	float: right;
}
.dashboardBody .cardUpperRight input {
	height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
}
.dashboardBody .cardCenter {
	margin: 9px 0;
}

.dashboardBody .cardLowerImg {
	display: inline-block;
}
.dashboardBody .cardLowerTxt {
	display: inline-block;
}
.dashboardBody .cardLower p {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: var(--secondary, #7C8DB5);
	margin-left: 15px;
}
.dashboardBody .lowerCardInnerTxt {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: var(--secondary, #7C8DB5);
	margin-left: 15px;
}
.dashboardSection2 {
	margin: 50px 0;
}


.dashboardBody .tableHeader {
	width: 100%;
	margin-bottom: 30px;
}
.dashboardBody .tableHeader p {
	color: var(--primary, #000);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	display: inline-block;
}
.dashboardBody .tableHeader button {
	float: right;
}
.dashboardBody .tableHeaderButton {
	color: white;
	background-color: #294a9c;
	font-weight: 500;
	border: 2px #294a9c solid;
	font-size: 16px;
	padding: 4px 12px;
	border-radius: 3px;
	/* margin-left: 15px; */
	min-width: 115px;
}

.dashboardBody .tableHeaderButton:hover {
	color: #fff;
	background-color: #f3734c;
	border-color:#f3734c;
}

/* .APActionButtonDashboard .btn-primary {
	background-color: #294a9c;
    border: none;
    margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
    color: white;
}
.APActionButtonDashboard .btn-secondary {
    background-color: #f3734c;
    border: none;
    margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
    color: white;
} */
/* Configuration - Change Logo */

.AdminChangeLogoBx {
    width: 300px;
    height: 160px;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.AdminChangeLogoBx img {
    margin: 0px 0px 0px 30px;
    width: 200px;
    height: 150px;
	padding: 20px;
}

/* Configuration - Change Payment Details */

.APPaymentDetailsRadio input{
	min-width: 20px;
	max-width: 20px;
	margin-left: 20px;
}
.APPaymentDetailsRadio label {
	margin-left: 2px;
}

/* Configuration - Change Color Theme */
.colorPickerBody {
	width: 100%;
}
.primaryColor {
	display: inline-block;
	margin-right: 150px;
}
.primaryColor label{
	font-weight: 400;
	margin-bottom: 10px;
}
.secondaryColor {
	display: inline-block;
}
.secondaryColor label{
	font-weight: 400;
	margin-bottom: 10px;
}
.defaultColor {
	margin: 40px 0 0 0;
}
/* Configuration - Add plan */

.NumberCheck {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
}
.NumberCheck input[type="checkbox"] {
    margin: 0 !important;
    vertical-align: top;
}
.NumberCheck label {
    padding-left: 10px;
    vertical-align: top;
    line-height: 21px;
}
.jobPost {
	width: 100%;
}
#jobPostInput{
	width: 20%;
	display: inline-block;
	height: 49px;
}
.checkBoxDiv {
	display: inline-block;
	padding-left: 10px;
}
#unlimitedCheckbx {
	display: inline-block;
	width: 100%;
    margin-top: 20px;
    padding-left: 20px;
}
.unlimitedLabel {
	display: inline-block;
    vertical-align: top;
    padding-left: 5px;
}
/* Configuration - Manage Sub Admin */
.APManageSubadmin .ManageSubAdminHeader {
	width: 100%;
}
.ManageSubAdminHeader h2 {
	display: inline-block;
	margin-top: 15px;
}
.ManageSubAdminHeader button {
	float: right;
	margin-right: 30px;
	margin-top: 8px;
}

.APDashboardSearchBx {
	position: relative;
	display: inline-block;
	margin: 25px 20px 0 0;
}
.APDashboardSearchBx input {

    border: none;
    outline: none;
    font-size: 17px;
    height: 50px;
    font-weight: 400;
    opacity: 0.8;
    min-width: 713px;
    max-width: 500px;
    border: 1px dashed #a7a6a6;
    padding: 20px 20px;
}
.APDashboardSearchBx .form-label {
    position: absolute;
    left: 12px;
    top: -13px;
    z-index: 9;
    background: #fff;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.6);
}

.manageSubadminPart1Sec1 {
	width: 100%;
	margin: 40px 0;
	border: 1px solid var(--border, #e0e0e0);
	border-radius: 10px;
	padding: 20px;
}
.manageSubadminPart1 h4{
	font-weight: 400;
	color: rgb(58, 57, 57);
}
.manageSubadminPart1Sec1 h4 {
	font-weight: 400;
	color: rgb(110, 109, 109);
}
.APDashboardSearchButtons {
	display: inline-block;
}
.manageSubadminPart2 {
	border: 1px solid var(--border, #e0e0e0);
	border-radius: 10px;
	padding: 20px;
	margin: 40px 0;
	width: 100%;
	overflow-y: auto;
}
.manageSubadminPart2 tr{
	vertical-align: top;
}
.manageSubadminPart2 span{
	cursor: pointer;
}
.manageSubadminpart2 .row .btn-primary{
	font-weight: 400;
	background-color: #294a9c;
	border: none;
	margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
}
.manageSubadminpart2 .row .btn-secondary{
	font-weight: 400;
	background-color: #f3734c;
	border: none;
	margin: 0 3px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 5px;
}

.manageSubadminpart3 {
	width: 100%;
}
.functionalityButton {
	gap: 20px;
	display: flex;
}
.manageSubadminpart3 .pagination {
	float: right;
	margin-right: 15px;
}

.manageRoleBody {
	margin-bottom: 40px;
}
.parentRole {
    margin: 20px 0 0 0;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    border-radius: 8px;
}

.parentRole .checkBox {
    width: 20px;
    height: 20px;
    max-width: 20px;
    min-width: 20px;
}
.parentRole label {
    padding: 0 20px 0 5px;
    margin: 0;
    line-height: 22px;
    vertical-align: top;
}
.childRole {
    margin: 15px 0 0 0;
	display: flex;
}

.APActionButton {
	min-width: 108px;
}
.APResumeListModal .adminModal h2 {
	font-size: 24px;
	margin-bottom: 8px;
}
.APResumeListModal .adminModal input {
	margin-right: 8px;
}
.APResumeListModal .adminModal .blocksBox h2 {
	color: #FF0B0F;
}
.APResumeListModal .adminModal .blocksBox input {
	margin-right: 8px;
	accent-color: #FF0B0F;
}
.APResumeListModal .adminModal .warningsBox h2 {
	margin-top: 8px;
}
.APResumeListModal .adminModal .buttons {
	margin-top: 16px;
	justify-content: space-between;
}
.APResumeListModal .adminModal .buttons .btn-secondary {
	border: none;
	color: white;
	display: flex;
    font-size: 16px;
    padding: 4px 16px;
    border-radius: 4px;
	align-items: center;
	background-color: #FF0B0F;
}
.APResumeListModal .adminModal .buttons .btn-secondary:disabled {
	opacity: 0.7;
}
.APResumeListModal .adminModal .buttons .btn-secondary:not(:disabled):hover {
	border-color: #294A9C;
    background-color: #294A9C;
}
.APResumeListModal .adminModal .buttons .btn-secondary.activated {
	border-color: #79C314;
    background-color: #79C314;
}
/* .APResumeListModal .adminModal .buttons .btn-secondary.activated:disabled {
	opacity: 0.7;
}
.APResumeListModal .adminModal .buttons .btn-secondary.activated:not(:disabled):hover {
	border-color: #294A9C;
    background-color: #294A9C;
} */
.APActionButton .btn-secondary {
    background-color: #FF0B0F;
    border: none;
	margin: 3px 3px;
    padding: 2px 2px;
    font-size: 14px;
    border-radius: 5px;
	color: white;
}
.APActionButton .btn-secondary:disabled,
.APActionButton .btn-primary:disabled {
    opacity: 0.7;
}
.APActionButton .btn-secondary.activated,
.cheaps .btn-primary.activated {
	border-color: #79C314;
    background-color: #79C314;
}
.cheaps .btn-primary.activated:hover {
	border-color: #294A9C;
    background-color: #294A9C;
}
.APActionButton .btn-primary {
    background-color: #294A9C;
    border: none;
	margin: 3px 3px;
    padding: 2px 2px;
    font-size: 14px;
    border-radius: 5px;
	color: white;
}
.pagination .Mui-selected {
    background-color: #294a9c;
}

/* APManageEmailSetting */

.siteSettingPaymentInfo {
	width: 100%;
}
.siteSettingPaymentInfo label{
	display: inline-block;
    margin-right: 15px;
    margin-left: 10px;
	vertical-align: top;

}

.siteSettingPaymentInfo input{
	display: inline-block;
	/* margin: 10px 0 0 10px; */
	/* padding: 10px 0 0 10px; */

}
.APMEmailSetting {
	margin-top: 3%;
}

.APMEmailSetting input {
	box-shadow: none !important;
	outline: none;
	font-size: 16px;
	min-width: -moz-fit-content;
	min-width: fit-content;
	border: 1px dashed #a7a6a6;
	color: #797979;
	height: 50px;
	opacity: 1;
}

.APMEmailSetting .button1 {
	color: white;
	background-color: #294a9c;
	min-width: 130px;
	height: 49px;
	font-weight: 500;
}
.APMEmailSetting .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color:#f3734c;
}

.APMEmailSetting .button2 {
	color: white;
	background-color: #f3734c;
	min-width: 150px;
	height: 49px;
	font-weight: 500;
}
.APMEmailSetting .button2:hover {
	color: #fff;
	background-color: #294a9c;
	border-color:#294a9c;
}

/* APEmployerlist */

.APEmployerlist {
	margin-top: 3%;
}
.verifiedUsername{
	margin-left: 3px;
}

.APELSection1 {
	display: inline-block;
	width: 100%
}
.APELSection1 h2{
	display: inline-block;
}
.APELSec1Right {
	float: right;
}
.APELSection2 {
	display: flex;
    width: 1283px;
    height: 131px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
}
.APELSection2 h4 {
	color: #5A5A5A;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}
.APEmployerlist .APELSection2Input {
	width: 320px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #E6EDFF;
}

.APEmployerlist .button1 {
	color: white;
	background-color: #294a9c;
	width: 120px;
    height: 42px;
    flex-shrink: 0;
}
.APEmployerlist .button1:hover {
	color: #fff;
	background-color: #f3734c;
	border-color:#f3734c;
}

.APEmployerlist .button2 {
	color: white;
	background-color: #f3734c;
	width: 120px;
    height: 42px;
    flex-shrink: 0;
}
.APEmployerlist .button2:hover {
	color: #fff;
	background-color: #294a9c;
	border-color:#294a9c;
}

.APELSection3 {
	display: inline-flex;
    width: 1400px;
    height: 311px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--border, #E6EDFF);
    background: var(--white, #FFF);
}
.APELSection3 .th {
	display: inline-flex;
    width: 150px;
    height: 21px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
	padding-top: 20px;
}
.APELSection3 .eachRow {
	width: 1237px;
    height: 54px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--border, #E6EDFF);
    background: rgba(255, 255, 255, 0.00);
}

.eachRow input {
	width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid #CEDAF9;
}
.eachRow .td {
	color: #474646;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
	display: inline-flex;
    width: 150px;
    height: 21px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
	padding-top: 20px;
}
/* APHomePageSLider */
.APHomepageSliderImage {
	width: 200px;
	height: 150px;
}

/* APJobseeker List */
.APJobseekerList .btn-primary{
	margin-top: 5px;
}
.APJobseekerList .btn-secondary{
	margin-top: 5px;
}

/* APAddJobseekers */

.APAJHeader {
	color: #294A9C;
	margin-bottom: 45px;
}

.APAJImagebox{
	margin-bottom: 45px;
}

.APAJImageArea {
	margin-top: 10px;
	width: 150px;
	height: 150px;
	background-color: rgb(233, 228, 228);
}

.APAJImagebox p {
	margin-top: 15px;
	color: rgba(71, 70, 70, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.APAJImagebox button {
	color: #f3734c;
    background-color: white;
    font-weight: 500;
    border: 2px #f3734c solid;
    font-size: 13px;
    padding: 9px 25px;
    border-radius: 3px;
	margin-top: 10px;
}

/* APEditJobseeker */
.otherSection {
	margin-bottom: 40px;
}
.otherSectionBody {
	display: flex;
	margin-bottom: 25px;
	margin-top: 15px;
}

.otherSection p{
	padding-top: 5px;
	font-size: 18px;
}
.ActiveButtonBox {
	margin-left: 20px;
}
.activeTrue {
	color: white;
	background-color: #1c7e1c;
	font-weight: 500;
	border: 2px #1c7e1c solid;
	font-size: 14px;
	padding: 3px 6px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 100px;
	margin-top: 5px;
}
.activeFalse {
	color: white;
	background-color: #a70f0f;
	font-weight: 500;
	border: 2px #a70f0f solid;
	font-size: 14px;
	padding: 3px 6px;
	border-radius: 3px;
	margin-left: 15px;
	min-width: 100px;
	margin-top: 5px;
}

/* APAppliedJobList */
.appliedJobListNoData {
	padding: 30px 15px;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* APManageCertificate */
.certificateBoxParent {
	/* display: flex; */
	margin-top: 20px;
}
.manageCertificateBox {
    width: 350px;
    height: 200px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transition: transform .5s ease;
}
.manageCertificateBox:hover{
    width: 350px;
    height: 200px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transform: scale(1.1);
}
.manageCertificateBox img{
    margin: 20px 0 0 0px;
	max-height: 150px;
}

.certificateFloatingButton {
	float: right;
    margin-top: 4px;
	transition: transform .5s ease;
}
.certificateFloatingButton:hover {
	transform: scale(1.2);
}

/* APAddCategory */

.APACHeader {
	color: #294A9C;
	margin-bottom: 45px;
}

.APACImagebox{
	margin-bottom: 45px;
}

.APACImageArea {
	margin-top: 10px;
	width: 150px;
	height: 150px;
	background-color: rgb(233, 228, 228);
}

.APACImagebox p {
	margin-top: 10px;
	color: rgba(71, 70, 70, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.APAddCategory p {
	margin-top: 15px;
	color: rgba(71, 70, 70, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.APACImagebox button {
	color: #f3734c;
    background-color: white;
    font-weight: 500;
    border: 2px #f3734c solid;
    font-size: 13px;
    padding: 9px 25px;
    border-radius: 3px;
	margin-top: 10px;
}

/* APJobs */
.editJobSelectedImageBox {
	width: 250px;
    height: 250px;
    border: 1px dashed grey;
    border-radius: 6px;
    margin: 10px 0;
}
.ActionBtn {
	display: flex;
}
#traditionalLink {
	color: #294A9C;
}

/* APPayment History */
.APTransactionList {
	margin-bottom: 20px;
}
.APTransactionListButton{
	color: #294a9c;
    background-color: white;
    font-weight: 400;
    border: 1px #294a9c solid;
    font-size: 14px;
    padding: 9px 25px;
    border-radius: 5px;
    /* margin-left: 10px; */
    min-width: 150px;
    /* max-width: 30px; */
}

/* APManageNewsletter */
.unsubscribeListNoData {
	padding: 30px 15px;
    border: 1px solid white;
    border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.editNewsletterForm {
	margin-bottom: 50px;
}

/* APBannerList */
.BannerList .BLAdvertisementImage {
	width: 200px;
	height: 100px;
}

/* APContent */
.APTextPages .APTPSec1 {
	margin-top: 50px;
}

/* APEmailTemplate */
.APEditEmailTemplateButtons {
	display: inline-block;
	margin-top: 20px;
}


/* APKeyword - Requested Keyword  */
.APRequestedKeyword .requestedSelect {
	border: none;
	outline: none;
	font-size: 17px;
	height: 49px;
	font-weight: 400;
	opacity: 0.8;
	min-width: 100px;
	max-width: 1100px;
	border: 1px dashed #a7a6a6;
	padding: 9px 20px;
	color: black;

}

@media only screen and (max-width:1300px){
	/* Styling the page scroller */
/* Webkit (Safari and Chrome) */
::-webkit-scrollbar {
	width: 5px; /* Adjust the width of the scrollbar as needed */
  }

  ::-webkit-scrollbar-thumb {
	background-color: #f3734c; /* Change to the color you want */
	border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  }
	.navbardefault .navitems {
		font-size: 14px;
	}
	.navButton1 {
		color: white;
		/* background-color: #294a9c; */
		font-weight: 500;
		/* border: 2px #294a9c solid; */
		font-size: 14px;
		padding: 6px 6px;
		border-radius: 3px;
		margin-left: 15px;
		min-width: 100px;
		margin-top: 5px;
	}
	.navButton2 {
		color: #f3734c;
		background-color: white;
		font-weight: 500;
		border: 2px #f3734c solid;
		font-size: 14px;
		padding: 6px 6px;
		border-radius: 3px;
		margin-left: 10px;
		min-width: 100px;
		margin-top: 5px;
	}
}



/* MEDIA QUERY FOR MOBILE RESPONSIVENESS */

@media only screen and (max-width:767px){
	/* Styling the page scroller */
/* Webkit (Safari and Chrome) */
::-webkit-scrollbar {
	width: 5px; /* Adjust the width of the scrollbar as needed */
  }

  ::-webkit-scrollbar-thumb {
	background-color: #f3734c; /* Change to the color you want */
	border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  }
	.RightDotImg {
		right: 0;
	}
	.LeftDotImg {
		left: 0;
	}
	.loader-container{
		position: fixed;
		width: 100%;
		height: 100%;
		background: #ffffffc0 url('/public/Images/loaderNew.gif') no-repeat center;
		z-index: 999;
		background-size: 100px;
	}
	.navButton1 {
		color: white;
		/* background-color: #294a9c; */
		font-weight: 500;
		/* border: 2px #294a9c solid; */
		font-size: 13px;
		padding: 9px 20px;
		border-radius: 3px;
		margin-left: 15px;
		min-width: 135px;
	}


	/* Footer */
	.FooterLinks h3 {
		color: #fff;
		font-size: 22px;
		font-weight: 500;
		padding-top: 6px;
	}
	.footerPlusLink {
		color: #fff;
		float: right;
		font-size: 22px;
		margin-bottom: 32px;
	}
	.FooterLinks .contractsLinks a {
		max-width: 100%;
	}
	.SocialIcons {
		font-size: 10px;
	}
	.SocialIcons a {
		font-size: 14px;
	}
	.SearchIcon {
		padding: 11px 0;
		display: inline-block;
		vertical-align: top;
		/* margin: 0 3px; */
		margin: 9px 0 0 18px;
	}
	.navbardefault .navitems {
		/* margin: 15px 0 0 17px; */
		font-size: 17px;
		font-weight: 400;
		text-align: left;
		background-color: white;
	}
	/* Userpage */
	.sliderbody .owl-carousel {
		height: 424px;
	}
	.sliderbody .owl-carousel .owl-stage-outer {
		height: 100%;
	}
	.sliderbody .owl-carousel .owl-stage {
		height: 100%;
	}
	.sliderbody .owl-carousel .owl-item {
		height: 100%;
	}
	.sliderDiv {
		height: 100%;
	}
	.owl-carousel .owl-item img {
		height: 100%;
		object-fit: cover;
	}
	.RightDotImg img{
		padding-top: 30px;
	}
	.LeftDotImg img {
		padding-top: 515%;
	}
	.JcardFooterItem1 {
		margin-top: 9px;
	}
	.ImageCard {
		display: inline-block;
	}
	.catCardBody {
		text-align: center;
	}
	.BrowseJobstab .btn {
		margin-top: 10px;
		color: white;
		font-size: 16px;
		position: relative;
		padding: 6px;
		font-weight: 500;
	}
	.DownloadAppSection .section1text {
		text-align: center;
		margin-top: -100px;
	}
	.DownloadAppSection .section1text h2 {
		font-size: 35px;
	}
	.DownloadAppSection .section1text h3 {
		font-size: 25px;
	}
	.DownloadRightImg {
		text-align: center;
	}
	.DownloadRightImg .girlImage {
		width: 50%;
		margin-top: 30px;
		/* margin-left: 110px; */
	}
	.DownloadRightImg .phoneImage {
		width: 50%;
		margin: 0 0 0px 100px;

	}
	.list {
    	font-weight: 300;
	}
	.sixthBlock .section2 .flexImage {
		margin-top: 20px;
	}
	.MembershipCard .btn-primary {
		background: #294a9c;
		border: none;
		border-radius: 3px;
		font-family: Roboto,sans-serif;
		font-size: 16px;
		font-weight: 500;
		margin-top: 30px;
		padding: 10px 19px;
		width: 84%;
	}
	.MembershipCard h2 {
		color: rgba(41,74,156,.9);
		font-size: 40px;
		font-weight: 600;
		margin: 20px 0 0;
	}
	.planDetailsJobseeker .parent .child1{
		max-width: 77%;
		min-width: 86%;
	}

	/* Blog */
	.blogPageSection1 {
		padding: 45px 0 40px 0;
	}
	.blogPagination {
		margin: 15px 0 30px 0;
	}
	.DBPSection1 {
		padding: 45px 0 40px 0;
	}
	.DBPSection2 img {
		margin-bottom: 20px;
	}
	/* For Employers */
	.forEmployers {
		overflow: hidden;
	}
	/* About us */
	.aboutUs {
		overflow: hidden;
	}
	.aboutusSection1 {
		padding: 45px 0 40px 0;
	}
	.aboutusSection1 h1{
		font-size: 24px;
	}
	.aboutusSection3 .row {
		margin: 30px 0 15px 0;
	}
	.aboutusImage {
		margin-bottom: 20px !important;
	}
	.col.aboutusSection3Col {
		max-width: 100%;
		display: inline-block;
		flex: auto;
		margin: 20px 0;
	}
	.aboutusSection3 h1 {
		color: #f3734c;
		font-size: 42px;
		font-weight: 600;
	}
	.aboutusDivider {
		display: none;
	}
	/* How It Works */
	.privacyPolicy .card {
		margin-top: 30px;
	}
	/* Blog  */
	.blogPagination {
		text-align: center;
		/* padding-right: 20px; */
		margin-top: 20px;
	}
	.paginationDetail {
		margin: 10px 0;
		text-align: center;
	}
	/* Contact Us */
	.contactUs {
		overflow-x: hidden;
	}
	.ContactSection1 {
		padding: 45px 0 40px 0;
	}
	.ContactDetails {
		width: 100%;
		display: flex;
		padding: 22px;
		margin: 15px 1%;
		text-align: left;
		min-height: 130px;
		align-items: center;
		border-radius: 10px;
		border: 1px #ddd solid;
	}
	.ContactTopHadding .card-title {
		font-size: 20px;
	}
	.ContactTopHadding .card-body {
		font-size: 17px;
	}
	.ContactForm {
		margin-bottom: 20px;
	}
	/* FAQ */
	.section1Faq {
		padding: 0px 0 40px 0;
	}
	.faqSection1 {
		padding: -70px 0;
	}
	.section2Faq .col-lg-5{
		text-align: center;
	}
	.section2Faq .accordion-button p {
		font-size: 20px;
	}
	.section2Faq .accordion-body p {
		font-size: 18px;
	}
	.faqSection1 .card {
		max-width: 100%;
		font-size: 500;
		box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
		border: 0;
	}
	.section2Faq .upperPart {
		margin-top: 25px;
	}
	.faqBodySection1 {
		margin-bottom: 23px;
	}

	/* Search Job Page */
	.Jcard {
		min-height: 280px;
		position: relative;
		text-align: start;
		border: 1px solid #bcbcbc;
		border-radius: 20px;
		padding: 16px;
		transition: 0.5s;
		margin-bottom: 25px;
	}
	.shareNodeJobCard {
		top: 19px;
	}

	/* Forgot Password */
	.forgotPasswordCard {
		width: 100%;
		margin: 0px auto;
	}

	/* Sidebar Transition */
	.SBBody {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.9s ease-in-out;
	}
	.SBBody.open {
		max-height: 1000px; /* Adjust as needed */
		  transition: max-height 0.9s ease-in-out;
	}

	.IADMainBx {
		margin-top: 10px;
	}
	.IADMainBx li {
		list-style: none;
	}


	/* Jobseeker Page */
	.editProfile select {
		border: none;
		outline: none;
		font-size: 17px;
		font-weight: 400;
		color: black;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}
	.editProfile input {
		color: black;
		border: none;
		outline: none;
		font-size: 17px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
	}
	.PageHeader {
		margin-top: 20px;
	}
	.PageHeader li {
		list-style: none;
	}
	.bottomButtons {
		gap: 10px;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
	}
	.bottomButtons button {
		width: 100%;
	}

	.JobseekerProfileBxDetailTop {
		text-align: center;
	}
	.JobseekerProfileBxDetailTop h3 {
		margin-top: 10px;
		font-size: 18px;
		padding-right: 20px;
	}
	.ProfileDetails {
		text-align: left;
		padding-left: 70px;
	}
	.JobseekerEducationDetails {
		width: 100%;
		display: inline-block;
	}
	.CurrentPlanBx {
		display: inline-block;
		width: 100%;
	}
	.CurrentPlanBx .btn-primary {
		float: right;
	}
	.myProfileLinks {
		text-align: center;
	}
	.button1 {
		display: inline-block;
	}
	.myProfileEachButton {
		margin: 20px 0px 0 0;
	}

	.CurrentPlanBx label {
		width: auto;
	}
	.JobseekerCertificatesDetails ul li{
		width: 150px;
	}
	.jobseekerLogin form {
		padding: 0;
	}
	.employerLogin form {
		padding: 0;
	}
	.employerLogin input {
		border: none;
		outline: none;
		font-size: 14px;
		height: 45px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}
	.jobseekerLogin input {
		border: none;
		outline: none;
		font-size: 14px;
		height: 45px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
	}
	.button1 {
		font-size: 14px;
	}
	.button2 {
		font-size: 14px;
	}
	.EPRegistrationBottomText {
		font-size: 13px;
		margin-bottom: 10px;
	}
	.SJPSection1 .formItems {
		display: block;
		padding: 25px 12px;
		align-items: start;
	}
	.searchItems {
		width: 100%;
		margin: 10px 0 !important;
	}
	/* Jobseeker - Edit Profile */
	.JSEPFinalButton {
		text-align: center;
	}
	/* Jobseeker - Education */
	.jobseekerAddMore {
		margin-top: 20px;
	}
	.bottomButtonsEducation {
		text-align: center;
		margin-left: -28px;
	}
	/* Jobseeker - Professional Registration */
	.specialJSPR .form-label{
		font-size: 15px;
	}
	.jobseekerAddMore button {
		margin-top: -67px;
	}
	/* Jobseeker - MakeACV */
	.headerMakeCV {
		margin-top: 20px;
	}
	.makeCV .headerMakeCV img {
		padding: 5px 0 15px 0;
	}
	.makeaCVBottomBotton {
		margin-top: 40px;
		text-align: center;
	}

	/* Jobseeker - Payment History */
	.empPaginationData {
		margin-left: 0;
		text-align: center;
	}
	.empPaginationButton {
		margin: 20px 0 20px 0;
	}
	/* Jobseeker - Mail Detail  */
	.mailDetail h3 {
		padding-top: 5px;
	}
	/* Jobseeker - Manage Alerts */
	.JSEditAlert h3 {
		padding-top: 5px;
	}

	/* Jobseeker - Change Photo */
	.buttonsSec {
		text-align: center;
	}

	/* Employer */
	/* Employer - Create Job */
	.createJob h3 {
		padding-top: 5px;
	}
	/* Employer - Manage Job */
	.MJHeader button {
		color: white;
		background-color: #294a9c;
		font-weight: 500;
		border: 2px #294a9c solid;
		font-size: 12px;
		padding: 9px 20px;
		border-radius: 3px;
	}

	/* Employer - Inner Account Details */
	.MyProfileImg {
		width: 100px;
		height: 100px;
	}
	.MyProfileDetails {
		margin-top: 10px;
		padding: 6px 10px;
	}
	.UploadBackBg {
		position: absolute;
		right: 0;
		bottom: 0;
		border-radius: 0;
		background-color: #294a9c;
		border: none;
		padding: 3px 15px;
	}
	.JobseekerProfileSearch {
		margin-top: 10px;
		flex-direction: column;
	}
	.JobseekersContentTop ul {
		display: inline-block;
		width: 100%;
	}
	.JobseekersContentTop ul li {
		width: 84px;
		text-align: center;
		margin-bottom: 20px;
		display: inline-block;
	}
	.JobseekersContentSearch {
		display: block;
		/* float: right; */
		width: 100%;
		margin-top: 30px;
		position: relative;
	}
	.JobseekersSearchContent {
		padding-left: 0;
		padding-right: 0;
	}
	.JobseekersSearchContent th p {
		font-size: 14px;
	}
	.EPEJimageViewer {
		margin-top: 10px;
	}
	.EPEJimageViewer img{
		width: 138px;
		margin-top: 10px;
	}
	.JobseekersTabsDetailsList label {
		width: 40%;
		color: #4a4948;
		font-size: 15px;
		margin-top: 6px;
		vertical-align: top;
		font-weight: 600;
	}
	.JobseekersTabsDetailsList span {
		width: 58%;
		color: #4a4948;
		font-size: 15px;
		display: inline-block;
	}



	/* Employer - Payment History */
	.paymentHistory .paymentButton {
		font-size: 14px;
		min-width: 90px;
		padding: 9px 2px;
	}

	/* Employer - User detail */
	.FLPRightSideHeader {
		width: 100%;
	}
	.FLPInnerRight {
		float: right;
	}
	.FLPlink {
		display: block;
		text-align: left;
	}
	#FLLink{
		margin-left: 2px;
	}
	.ImageSpace {
		margin-bottom: 20px;
	}
	.FLPLeftSideHeader .ImageSpace {
		width: 190px;
		height: 200px;
		background-color: #cfcccc;
	}

	/* Employer - Import Jobseeker */
	.importJobseeker h3 {
		padding-top: 5px;
	}
	/* Employer - Edit Profile */
	.editProfile h3 {
		padding-top: 5px;
	}
	/* Employer - Change Password */
	.changePassword h3 {
		padding-top: 5px;
	}
	/* Employer - Change Logo */
	.changeLogo h3 {
		padding-top: 5px;
	}

	/* Employer - My Profile */

	.myProfile h3 {
		padding-top: 7px;
	}
	.myProfile input {
		border: none;
		outline: none;
		font-size: 17px;
		height: 55px;
		font-weight: 400;
		opacity: 0.8;
		min-width: -moz-fit-content;
		min-width: fit-content;
		border: 1px dashed #a7a6a6;
		padding: 0 20px;
		box-shadow: none;
	}
	.ChoosPlanBx {
		display: inline-block;
		width: 100%;
		border: 1px dashed #d0cece;
		padding: 10px 20px;
		border-radius: 5px;
		transition: all .3s ease-in-out;
	}
	/* Employer - Membership Plan */
	.MembershipCard h4 {
		color: #25242a;
		font-size: 22px;
		font-weight: 500;
	}
	.MembershipCard {
		padding: 30px 3px;
	}
	.MembershipCard .btn-secondary {
		background: #f3734c;
		border: none;
		border-radius: 3px;
		font-size: 14px;
		font-weight: 500;
		font-family: 'Roboto', sans-serif;
		padding: 10px 17px;
		width: 84%;
		margin-top: 30px;
	}
	.membershipCardUpperPart {
		min-height: 130px;
	}
	.ApplyAll {
		border-top: 2px solid rgba(222,222,222,0.5);
		margin-top: 20px;
		padding: 21px 0px 0px;
		text-align: center;
		margin-right: 19px;
	}
	.applyoption {
		padding: 23px 0 0 12px;
		text-align: left;
	}
	.MembershipCard h2 {
		color: rgba(41,74,156,.9);
		font-size: 33px;
		font-weight: 600;
		margin: 20px 0 0;
	}
	.MembershipCard .btn-primary {
		background: #294a9c;
		border: none;
		border-radius: 3px;
		font-family: Roboto,sans-serif;
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
		padding: 10px 17px;
		width: 84%;
	}
	.ApplyAll .form-check-label {
		color: #25242a;
		font-size: 14px;
		opacity: 0.7;
	}
	.applyoption li {
		font-size: 15px;
		/* margin-left: 20px; */
		text-align: center;
	}

	.membershipPlan img {
		padding: 0px 0px 20px 0px;
	}

	/* Employer - Sidebar */
	.sidebarEachHeader h3 {
		font-size: 20px;
		color: white;
	}
	.sidebarEachHeader a {
		color: white;
		float: right;
		padding: 20px;
	}
	.sidebarEachHeader .sidebarPlusLink {
		margin-bottom: 0;
		font-size: 24px;
	}
	.SidebarSection1 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}
	.SBHeader .sidebarEachHeader a {
		color: #3a3a3a;
		float: right;
		padding: 9px 0 0;
	}
	.SidebarSection2 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}
	.SBHeader hr {
		display: none;
	}
	.SidebarSection3 .SBHeader h3 {
		color: black;
		padding-top: 12px;
	}

	/* After giving to madan sir. Append this code in his code.*/

	/* Job description */
	.logo-detail {
		display: inline-block;
		width: 150px;
		height: 150px;
		background: #f1f1f1;
		border-radius: 5px;
		overflow: hidden;
	}
	.job-meta-detail {
		display: inline-block;
		width: 50%;
	}
	.JobDetailBtnBx {
		text-align: center;
		margin-top: 50px;
	}
	.JDSection2 iframe {
		width: 100%;
	}

	/* Login page */
	.reCaptchaLogin iframe {
		width: 100%;
		transform: scale(0.77);
		transform-origin: 0 0;
	}

	.reCaptchaLogin {
		position: relative;
	}
	.reCaptchaLogin .CaptchaVerify {
		position: absolute;
		left: 0;
		bottom: -6px;
		font-size: 14px;
	}

	/* Register Page */
	.leftSection .card-title p{
		font-size: 5px;
	}

	/* Company Profile */
	.CPFPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}
	.CPFifthPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}
	.CPTPart1 {
		display: inline-block;
		margin-right: 0;
		width: 100%;
		color: #000 !important;
	}
	.companyProfileCardFooter .companyProfileContact {
		width: 100%;
		padding-top: 10px;
	}
	.CPFooter {
		display: inline-block !important;
	}
	.onlyLink {
		margin-top: 20px;
	}
	.companyProfileCardRight {
		margin-bottom: 20px;
		overflow: hidden;
	}
	.companyProfileCardRight .row {
		overflow: hidden;
	}
	.dashboardCard1 {
		margin-bottom: 10px;
	}
	.dashboardCard2 {
		margin-bottom: 10px;
	}
	.dashboardCard3 {
		margin-bottom: 10px;
	}
	.dashboardCard4 {
		margin-bottom: 10px;
	}



	/* ADMIN PANEL  */

	/* Admin Navbar */
	.APNavSectionLeft {
		margin-left: 0px;
	}
	.APNavSectionRight .part1 {
		margin-right: 12px;
	}
	.APNavSectionRight .part1 a {
		margin: 0 10px;
		padding: 0;
	}
	.APNavSectionRight .part2 .SearchIcon {
		margin: 0;
		padding: 0;
	}
	.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu {
		left: -70px;
		right: 10px;
		width: 283px;
		position: absolute;
		right: auto;
	}
	.APNavSectionRight .part2 .APNavInner3 .custom-dropdown-menu .dropdownMenu {
		text-align: left;
		margin: 5px 0;
	}
	.dropdownMenuLower {
		margin-top: 20px;
		background-color: #f3734c;
		padding: 8px 0;
		border-radius: 7px;
	}

	/* Admin Sidebar */
	.Sidebar {
		padding-bottom: 0;
		position: initial;
		width: 100%;
		margin-top: 84px;
		height: auto;
	}
	.site-min-height {
		margin: 20px 0 30px 0;
		padding: 0 15px;
	}
	.SidebarList {
		margin-top: 0;
	}

	/* Admin Dashboard */
	.dashboardHeader {
		margin: 15px;
	}
	.dashboardSection1 {
		width: 100%;
		margin-top: 30px;
		margin-left: 0px;
	}
	.recharts-wrapper {
		width: 100% !important;
		height:240px !important;
	}
	.recharts-surface {
		width: 100%;
		height:240px;
	}

	.recharts-legend-wrapper {
		display: none;
	}

	.loginCardStyles {
		width: 100%;
		padding: 20px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		border: none;
		margin: 150px  auto 0;
	}
	.APPaymentDetailsRadio input {
		min-width: 15px;
		max-width: 15px;
		margin-left: 20px;
		height: 13px;
	}

	.APBasic input {
		border: none;
		outline: none;
		font-size: 17px;
		height: 60px;
		font-weight: 400;
		opacity: 0.8;
		min-width: 300px;
		max-width: 350px;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
	}
	.APBasic select {
		border: none;
		outline: none;
		font-size: 17px;
		height: 60px;
		font-weight: 400;
		opacity: 0.8;
		min-width: 100px;
		max-width: 1100px;
		border: 1px dashed #a7a6a6;
		padding: 20px 20px;
		color: black;
	}
	.APButton3 {
		margin: 5px;
		width: 100%;
	}
	.APButton4 {
		margin: 5px;
		width: 100%;
	}
	.navButton1 {
		color: white;
		font-weight: 500;
		font-size: 13px;
		padding: 9px 20px;
		border-radius: 3px;
		min-width: 135px;
	}
	.breadCumb {
		margin: 10px 25px;
	}
	.breadCumb1 {
		margin: 20px 0px;
	}
	.adminPageHeading {
		margin: 0 25px
	}
	.adminForm {
		padding: 0 25px;
	}
	.APDashboardSearchBx {
		margin: 25px 0px 0 0;
		width: 100%;
	}
	.APDashboardSearchBx input {
		max-width: 100%;
		min-width: 100%;
	}
	.manageSubadminPart2 {
		overflow-y: auto;

	}
	.manageSubadminpart3 .pagination {
		float: right;
		margin-top: 40px;
	}
	.ManageSubAdminHeader button {
		float: right;
		margin-right: 0px;
	}

	.APHomepageSliderImage {
		width: 100px;
		height: 60px;
	}
	.APManageSubadmin .ManageSubAdminHeader {
		width: 100%;
		display: inline-block;
	}
	.ManageSubAdminHeader h2 {
		display: inline-block;
		margin-top: 15px;
		width: 100%;
	}
	.ManageSubAdminHeader button {
		float: left;
		margin-right: 10px;
		margin-left: 0;
		margin-top: 20px;
	}
	.selectedInputImage {
		width: auto;
		height: 150px;
		/* border: 1px solid grey; */
		/* border-radius: 10px; */
		margin: 10px 0;
	}
	.modalParent {
		margin: 10px 0 10px 0;
	}
	.modalLeft {
		width: 50%;
	}
	.modalRight {
		width: 48%;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.PageHeader {
        margin-top: 20px;
    }
	.sidebarEachHeader h3 {
		font-size: 20px;
		color: white;
	}
	.sidebarEachHeader a {
		color: white;
		float: right;
		padding: 20px;
	}
	.sidebarEachHeader .sidebarPlusLink {
		margin-bottom: 0;
		font-size: 24px;
	}
	.SidebarSection1 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}
	.SBHeader .sidebarEachHeader a {
		color: #3a3a3a;
		float: right;
		padding: 9px 0 0;
	}
	.SidebarSection2 .SBHeader h3 {
		color: black;
		padding: 15px 0 15px 0;
	}
	.SBHeader hr {
		display: none;
	}
	.SidebarSection3 .SBHeader h3 {
		color: black;
		padding-top: 12px;
	}
	.SBBody {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.9s ease-in-out;
	}
	.SBBody.open {
		max-height: 1000px; /* Adjust as needed */
		  transition: max-height 0.9s ease-in-out;
	}

	.IADMainBx {
		margin-top: 10px;
	}
	.IADMainBx li {
		list-style: none;
	}
}


/* styles.css */
.modalMain {
	width: 100%;
}

.adminModal {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	max-width: 700px;
	max-height: 500px;
	background-color: white;
	box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
	padding: 20px;
}
.modals .modalHead h1 {
	color: #f3734c;
}

/* Add a vertical scrollbar when needed */
/* .modal-content {
	max-height: 500px;
	overflow-y: auto;
	overflow-x: auto;
}


/* Close button style */
.close-button {
	position: absolute;
	/* top: 10px; */
	/* right: 10px; */
	float: right;
}
.modalParent {
	width: 100%;
	margin: 10px 0 10px 15px;
}
.modalLeft {
	display: inline-block;
	font-weight: 500;
	width: 36%;
	vertical-align: top;
	font-size: 18px;
}
.modalRight {
	display: inline-block;
	width: 62%;
	font-size: 18px;
}

.membershipModal {
    max-width: 100%;
    max-height: initial;
    background: transparent;
    border: none;
    box-shadow: none;
}
.leftData{
	font-size: 20px;
}
.rightData{
	font-size: 20px;
}
.emailTemplateModalAdmin{
	font-size: 20px;
	margin-left: 12px;
}
.dateInputs {
	margin-top: 18px;
    display: flex;
}
.dateFilter input {
	border: none;
    outline: none;
    font-size: 17px;
    height: 50px;
    font-weight: 400;
    opacity: 0.8;
    /* min-width: 100px; */
    max-width: 261px;
    border: 1px dashed #a7a6a6;
    padding: 20px 20px;
}
.dateFilter {
    position: relative;
	margin-right: 23px;
}
.dateFilter .form-label {
    position: absolute;
    left: 12px;
    top: -13px;
    z-index: 9;
    background: #fff;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.6);
}

.swal2-container{
	z-index:99999
}

.standartListStyle ul {
	padding-left: 32px;
}
.standartListStyle ol {
	padding-left: 32px;
}